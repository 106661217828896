import { Formik } from 'formik'
import { pick } from 'lodash'
import { getCountryByNameOrShortName } from 'node-countries'
import { useCallback } from 'react'
import { Borrower } from 'admin/services/api/borrowers'
import { Button } from 'components/Button'
import {
  Field,
  Form,
  Phone,
  Select,
  SocialSecurityNumber,
  FieldIcon,
} from 'components/Form'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { TextLink } from 'components/TextLink'
import { parseInitValues } from 'utils/form'
import { createScheme, email, required } from 'utils/schemas'
import styles from './styles.module.scss'

interface Props {
  borrower?: Borrower
  cancelText?: string
  saveText?: string
  saving?: boolean
  include?: string[]
  exclude?: string[]
  isFund?: boolean
  onBack?: () => void
  onSave: (borrower: Borrower) => void
  onCancel: () => void
  personName?: string
}

function FormBorrowerEntity({
  borrower = {} as Borrower,
  cancelText = 'Cancel',
  saveText = 'Save',
  include = [],
  exclude = [],
  isFund = false,
  saving,
  onBack,
  onSave,
  onCancel,
  personName,
}: Props) {
  const isNameIncluded = !exclude.includes('name')
  const isEmailIncluded = include.includes('email')
  const isNumFlippedIncluded = borrower.isBorrower
  const isNumConstructionsIncluded = borrower.isBorrower

  const BorrowerSchema = useCallback(
    () =>
      createScheme({
        ...(isNameIncluded ? { name: required } : {}),
        ...(isEmailIncluded ? { email } : {}),
      }),
    [isNameIncluded, isEmailIncluded]
  )
  const data = pick(borrower, [
    'entityType',
    'jurisdiction',
    'phone',
    'socialSecurityNumber',
    isNameIncluded ? 'name' : '',
    isEmailIncluded ? 'email' : '',
    isNumFlippedIncluded ? 'numFlipped' : '',
    isNumConstructionsIncluded ? 'numConstructions' : '',
  ])
  const initialValue: Borrower = {
    type: undefined,
    jurisdiction: '',
    phone: undefined,
    socialSecurityNumber: undefined,
    ...(isNameIncluded ? { name: personName ?? undefined } : {}),
    ...(isEmailIncluded ? { email: undefined } : {}),
    ...(isNumFlippedIncluded ? { numFlipped: undefined } : {}),
    ...(isNumConstructionsIncluded ? { numConstructions: undefined } : {}),
    ...parseInitValues(data),
  }

  return (
    <Formik
      initialValues={initialValue}
      enableReinitialize={true}
      validationSchema={BorrowerSchema}
      onSubmit={onSave}
      validateOnChange={false}
    >
      <Form modal>
        <div>
          <Grid columnGap={24}>
            {isNameIncluded && (
              <Grid.Item xs={12} sm={6}>
                <Field
                  name="name"
                  label="Name"
                  placeholder={isFund ? 'Acme Fund IV' : 'John'}
                  autoFocus
                />
              </Grid.Item>
            )}
            <Grid.Item xs={12} sm={6}>
              <Select
                name="entityType"
                label="Type"
                portal
                options={[
                  {
                    label: 'Corporation',
                    value: 'Corporation',
                  },
                  {
                    label: 'General Partnership',
                    value: 'General Partnership',
                  },
                  {
                    label: 'Limited Liability Company',
                    value: 'Limited Liability Company',
                  },
                  {
                    label: 'Limited Partnership',
                    value: 'Limited Partnership',
                  },
                  {
                    label: 'Trust',
                    value: 'Trust',
                  },
                  {
                    label: 'Other',
                    value: 'Other',
                  },
                ]}
              />
            </Grid.Item>
            <Grid.Item xs={12} sm={6}>
              <Select
                name="jurisdiction"
                label="Jurisdiction"
                portal
                placeholder="Jurisdiction"
                options={[
                  {
                    label: 'Country',
                    options: [
                      { label: 'United States', value: 'United States' },
                      { label: 'Canada', value: 'Canada' },
                    ],
                  },
                  {
                    label: 'State/Province',
                    options: [
                      ...(
                        getCountryByNameOrShortName('United States')
                          ?.provinces || []
                      ).map((province) => ({
                        value: province.name,
                        label: province.name,
                      })),
                      ...(
                        getCountryByNameOrShortName('Canada')?.provinces || []
                      ).map((province) => ({
                        value: province.name,
                        label: province.name,
                      })),
                    ],
                  },
                ]}
                defaultValue={initialValue.jurisdiction || undefined}
              />
            </Grid.Item>
            <Grid.Item xs={12} sm={6}>
              <SocialSecurityNumber
                label="Tax ID"
                name="socialSecurityNumber"
                mask="00-0000000"
              />
            </Grid.Item>
            {isNumFlippedIncluded && (
              <Grid.Item xs={12} sm={6}>
                <FieldIcon
                  type="number"
                  label="Number of Flips"
                  name="numFlipped"
                  mask="# flip(s)"
                />
              </Grid.Item>
            )}
            {isNumConstructionsIncluded && (
              <Grid.Item xs={12} sm={6}>
                <FieldIcon
                  type="number"
                  name="numConstructions"
                  label="Number of Projects"
                  mask="# project(s)"
                />
              </Grid.Item>
            )}
            <Grid.Item xs={12} sm={6}>
              <Phone name="phone" label="Phone" />
            </Grid.Item>
            {isEmailIncluded && (
              <Grid.Item xs={12} sm={6}>
                <Field
                  type="email"
                  name="email"
                  label="Email"
                  placeholder="Type your email"
                />
              </Grid.Item>
            )}
          </Grid>
        </div>
        <div className={styles.buttonsWithBack}>
          {onBack && (
            <TextLink variant="invert" onClick={onBack}>
              <Icon name={IconName.arrowLeft} size="sm" />
              Back
            </TextLink>
          )}
          <div className={styles.buttons}>
            <Button variant="tertiary" onClick={onCancel}>
              {cancelText}
            </Button>
            <Button loading={saving} type="submit">
              {saveText}
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  )
}

export default FormBorrowerEntity
