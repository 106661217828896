import {
  autoUpdate,
  flip,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  size,
} from '@floating-ui/react'
import { debounce } from 'lodash'
import { useState, useCallback, useMemo } from 'react'
import { Avatar } from 'components/Avatar'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Input } from 'components/Input'
import { IMail } from 'types'

interface Props {
  mail: IMail
  onChange: (payload: Partial<IMail>) => void
}

const responseTypes = [
  { name: 'Reply', value: 'reply', icon: IconName.arrowReply },
  { name: 'Reply all', value: 'reply-all', icon: IconName.arrowReplyAll },
  { name: 'Forward', value: 'forward', icon: IconName.arrowForward },
]

const Addressees = ({ mail, onChange }: Props) => {
  const [open, setOpen] = useState(false)
  const onChangeDebounced = useMemo(() => debounce(onChange, 300), [onChange])

  const handleOpenChange = useCallback((nextOpen: boolean) => {
    setOpen(nextOpen)
  }, [])

  const { x, y, strategy, context, refs } = useFloating({
    open,
    onOpenChange: handleOpenChange,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    middleware: [
      flip(),
      size({
        apply({ elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
          })
        },
      }),
    ],
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useClick(context, { event: 'click' }),
      useDismiss(context),
      useRole(context, { role: 'tree' }),
    ]
  )

  const isResponse = !!mail.replyTo || !!mail.replyAllTo || !!mail.forwardTo
  const responseType = useMemo(() => {
    if (mail.replyTo) {
      return 'reply'
    }
    if (mail.replyAllTo) {
      return 'reply-all'
    }
    if (mail.forwardTo) {
      return 'forward'
    }
    return undefined
  }, [mail])

  return isResponse ? (
    <Flex
      gap={10}
      alignItems="center"
      className="pb-3 px-6 border-0 border-b border-solid border-grey-200 text-grey-800"
    >
      <Flex
        gap={6}
        className="h-8 px-2 rounded cursor-pointer hover:bg-grey-100"
        {...getReferenceProps({
          ref: refs.setReference,
          onClick(e) {
            e.stopPropagation()
          },
        })}
      >
        <Icon
          name={responseTypes.find(({ value }) => value === responseType)!.icon}
          size="md"
          className="m-auto text-grey-700"
        />
        <Icon
          name={IconName.arrowDownFilled}
          size="md"
          className="m-auto text-grey-700"
        />
      </Flex>
      <Flex gap={10} alignItems="center">
        {mail.forwardTo ? (
          <Input />
        ) : (
          mail.to.map(({ name, email }) => (
            <Flex
              key={email}
              gap={6}
              alignItems="center"
              className="p-1.5 rounded border border-solid border-grey-200"
            >
              <Avatar
                id={name || email}
                name={name || email}
                className="!h-5 !w-5 flex content-center !basis-5 !text-[9px]"
              />
              <div className="text-sm font-bold text-grey-800">
                {name || email}
              </div>
            </Flex>
          ))
        )}
      </Flex>
      {open && (
        <FloatingPortal>
          <Flex
            stack
            gap={0}
            className="min-w-[170px] p-1 bg-white-100 shadow-300 rounded py-1 border-solid border-[1px] border-grey-200"
            onClick={(e) => e.stopPropagation()}
            {...getFloatingProps({
              ref: refs.setFloating,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                zIndex: 5,
              },
            })}
          >
            {responseTypes.map(({ name, value, icon }) => (
              <Flex
                key={value}
                gap={8}
                alignItems="center"
                className="py-1.5 px-2 rounded-sm hover:bg-grey-75 cursor-pointer"
                {...getItemProps({
                  onClick: () => {
                    setOpen(false)
                    console.log(value)
                  },
                })}
              >
                <Icon name={icon} size="md" className="text-grey-600" />
                <div className="leading-5 text-grey-900">{name}</div>
              </Flex>
            ))}
          </Flex>
        </FloatingPortal>
      )}
    </Flex>
  ) : (
    <>
      <div className="mx-6">
        <Flex
          alignItems="center"
          className="mb-3 pb-3 border-0 border-b border-solid border-grey-200 text-grey-800"
        >
          <div>From:</div>
          <Flex
            gap={6}
            alignItems="center"
            className="p-1.5 rounded border border-solid border-grey-200"
          >
            <Avatar
              id={mail.from.email}
              name={mail.from.name || mail.from.email}
              className="!h-5 !w-5 flex content-center !basis-5 !text-[9px]"
            />
            <div className="text-sm font-bold text-grey-800">
              {mail.from.name || mail.from.email}
            </div>
          </Flex>
        </Flex>
        <Flex
          gap={32}
          alignItems="center"
          className="pb-3 border-0 border-b border-solid border-grey-200 text-grey-800"
        >
          <div>To:</div>
          <Input
            value={mail.to?.[0]?.email}
            onChange={(e) =>
              onChangeDebounced({
                id: mail.id,
                to: [{ name: e.target.value, email: e.target.value }],
              })
            }
          />
        </Flex>
      </div>
      <Flex
        gap={32}
        alignItems="center"
        className="py-3 mx-6 border-0 border-b border-solid border-grey-200 text-grey-800"
      >
        <div>Subject:</div>
        <Input
          value={mail.subject}
          onChange={(e) =>
            onChangeDebounced({
              id: mail.id,
              subject: e.target.value,
            })
          }
        />
      </Flex>
    </>
  )
}

export { Addressees }
