import { flatten } from 'lodash'
import { PanelAddress } from 'admin/components/PanelAddress'
import { PanelEntityBanking } from 'admin/components/PanelEntityBanking'
import { useUpdateVendorBanking } from 'admin/hooks/use-banking'
import {
  useAddVendorAddress,
  useUpdateVendorAddress,
} from 'admin/hooks/use-vendors'
import { Vendor, VendorDetails } from 'admin/services/api/vendors'
import { Grid } from 'components/Grid'
import PanelEmail from 'components/Panel/PanelEmail'
import { PanelPersonCustom } from 'components/Panel/PanelPersonCustom'
import { useSession } from 'hooks/use-session'
import { Email, Field } from 'types'
import PanelVendorLinkedAccounts from './PanelLinkedAccounts'
import PanelPersonalInformation from './PanelPersonalInformation'
import styles from './styles.module.scss'

interface Props {
  vendor: Vendor
  fields?: Field[]
}

function TabGeneral({ vendor, fields }: Props) {
  const { Can } = useSession()
  const isIndividual = !vendor.type || vendor.type === 'individual'
  const { mutate: addAddress, isPending: addingAddress } = useAddVendorAddress(
    vendor.id
  )
  const { mutate: updateAddress, isPending: updatingAddress } =
    useUpdateVendorAddress(vendor.id)
  const { mutate: updateBanking, isPending: savingBanking } =
    useUpdateVendorBanking(vendor.id)

  return (
    <>
      <Grid gap={16}>
        <Grid.Item sm={12} md={6} className={styles.panels}>
          <PanelPersonalInformation vendor={vendor} />
          <PanelPersonCustom
            personRoleType="vendor"
            person={vendor}
            fields={fields}
          />
          <PanelAddress
            person={vendor}
            isLoading={addingAddress || updatingAddress}
            addAddress={addAddress}
            updateAddress={updateAddress}
          />
          <Can do="read" on="banking">
            <PanelEntityBanking
              banking={vendor.banking}
              title="Banking Info"
              updateBanking={updateBanking}
              isSaving={savingBanking}
            />
          </Can>
        </Grid.Item>
        <Grid.Item sm={12} md={6} className={styles.panels}>
          <PanelEmail
            id={vendor.id}
            type={isIndividual ? 'individual' : 'entity'}
            personType="vendor"
            isInvited={['invited', 'active'].includes(vendor.status)}
            emails={vendor.emails as Email[]}
            readonlyEmails={flatten(
              vendor.managers?.map((m) => m.emails || [])
            )}
          />
          <PanelVendorLinkedAccounts vendor={vendor as VendorDetails} />
        </Grid.Item>
      </Grid>
    </>
  )
}

export default TabGeneral
