import clsx from 'clsx'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'

interface Props {
  name: string
  isSelected?: boolean
  onClickItem: () => void
}

const PersonItem = ({ name, isSelected = false, onClickItem }: Props) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      onClick={onClickItem}
      className="group py-2.5 pr-2 pl-3 border-0 border-b border-solid border-grey-100 cursor-pointer hover:bg-grey-50"
    >
      <div
        className={clsx(
          'leading-5 group-hover:text-grey-800',
          isSelected ? 'text-grey-800' : 'text-grey-700'
        )}
      >
        {name}
      </div>
      <Icon
        name={isSelected ? IconName.arrowDown : IconName.arrowRight}
        className="text-grey-500 group-hover:text-grey-800"
      />
    </Flex>
  )
}

export { PersonItem }
