import { useCallback, useState } from 'react'
import { SelectPerson } from 'admin/components/SelectPerson'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Modal } from 'components/Modal'
import { useThreadContext } from '../ThreadContext'

interface Props {
  onClose: () => void
}

const ModalLinkContact = ({ onClose }: Props) => {
  const [contactId, setContactId] = useState<string>()
  const { thread, updateThread, isUpdating } = useThreadContext()

  const handleLink = useCallback(() => {
    updateThread(
      { people: [...thread.people.map(({ id }) => id), contactId!] },
      {
        onSuccess: () => {
          onClose()
        },
      }
    )
  }, [updateThread, thread, contactId, onClose])

  return (
    <Modal title="Link to Contact" onClose={onClose}>
      <Flex stack>
        <Flex stack gap={6}>
          <div className="text-grey-800">Select a contact</div>
          <SelectPerson
            personId={contactId}
            placeholder="Add a contact"
            onChange={({ personId }) => setContactId(personId)}
          />
        </Flex>
        <Flex justifyContent="flex-end" alignItems="center" gap={10}>
          <Button variant="tertiary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={handleLink}
            loading={isUpdating}
            disabled={!contactId}
          >
            Link
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export { ModalLinkContact }
