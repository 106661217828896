import { LoanStatus } from './loan-status'

export type Substatus =
  | 'New Application'
  | 'Under Review'
  | 'Initial Review Completed'
  | 'Term Sheet Sent'
  | 'Terms Accepted'
  | 'Pending Appraisal'
  | 'Underwriting Review'
  | 'Credit Committee'
  | 'Closing Review'
  | 'Pending Closing'
  | 'Pending Servicing Approval'
  | 'In Servicing'
  | 'Transferred'

export const substatusOptions: {
  value: Substatus
  label: Substatus
  status: LoanStatus
  color?: 'red' | 'blue' | 'green' | 'yellow'
}[] = [
  {
    value: 'New Application',
    label: 'New Application',
    status: 'lead',
    color: 'blue',
  },
  { value: 'Under Review', label: 'Under Review', status: 'lead' },
  {
    value: 'Initial Review Completed',
    label: 'Initial Review Completed',
    status: 'lead',
    color: 'green',
  },
  {
    value: 'Term Sheet Sent',
    label: 'Term Sheet Sent',
    status: 'lead',
    color: 'blue',
  },
  {
    value: 'Terms Accepted',
    label: 'Terms Accepted',
    status: 'processing',
    color: 'green',
  },
  {
    value: 'Pending Appraisal',
    label: 'Pending Appraisal',
    status: 'processing',
  },
  {
    value: 'Underwriting Review',
    label: 'Underwriting Review',
    status: 'underwriting',
  },
  {
    value: 'Credit Committee',
    label: 'Credit Committee',
    status: 'underwriting',
  },
  { value: 'Closing Review', label: 'Closing Review', status: 'underwriting' },
  {
    value: 'Pending Closing',
    label: 'Pending Closing',
    status: 'approved',
    color: 'green',
  },
  {
    value: 'Pending Servicing Approval',
    label: 'Pending Servicing Approval',
    status: 'closed',
    color: 'blue',
  },
]

export function substatusToColor(substatus: Substatus) {
  const options = substatusOptions.find(
    (o) => o.value === (substatus as string)
  )

  return (options && options.color) || 'yellow'
}
