import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { pathTo } from 'admin/path-to'
import {
  WidgetNumber,
  WidgetLoansPerMonth,
  WidgetChartCurrency,
} from 'components/Dashboard'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { useSession } from 'hooks/use-session'
import { formatUsd } from 'utils/currency'

function Dashboard() {
  const { ability } = useSession()
  const navigate = useNavigate()
  const [loaded, setLoaded] = useState(0)

  const showDashboard = ability.can('read', 'dashboard')

  useEffect(() => {
    if (!showDashboard) {
      navigate(pathTo('tasks', 'tasks'))
    }
  }, [showDashboard])

  if (!showDashboard) {
    return <></>
  }

  return (
    <MainContent>
      <Flex stack gap={24}>
        <PageTop title="Dashboard" />
        <Grid gap={16}>
          <Grid.Item xs={12} sm={12} md={6} lg={3}>
            <WidgetNumber
              title="Principal Balance"
              type="servicing-loan-balance"
              format={formatUsd}
              variant="secondary"
              onLoad={() => setLoaded((i) => i + 1)}
              onClick={() => navigate(pathTo('servicing'))}
            />
          </Grid.Item>
          <Grid.Item xs={12} sm={12} md={6} lg={3}>
            <WidgetNumber
              title="Active Loans"
              type="active-loans"
              variant="secondary"
              onLoad={() => setLoaded((i) => i + 1)}
              onClick={() => navigate(pathTo('servicing'))}
            />
          </Grid.Item>
          <Grid.Item xs={12} sm={12} md={6} lg={3}>
            <WidgetNumber
              title="Active Borrowers"
              type="total-borrowers"
              variant="secondary"
              enabled={loaded >= 2}
              onLoad={() => setLoaded((i) => i + 1)}
              onClick={() => navigate(pathTo('borrowers'))}
            />
          </Grid.Item>
          <Grid.Item xs={12} sm={12} md={6} lg={3}>
            <WidgetNumber
              title="Active Investors"
              type="total-investors"
              enabled={loaded >= 2}
              variant="secondary"
              onLoad={() => setLoaded((i) => i + 1)}
              onClick={() => navigate(pathTo('investors'))}
            />
          </Grid.Item>
          <Grid.Item xs={12} sm={12} md={6} lg={6}>
            <WidgetLoansPerMonth
              enabled={loaded >= 4}
              variant="secondary"
              onLoad={() => setLoaded((i) => i + 1)}
              onClick={() => navigate(pathTo('servicing'))}
            />
          </Grid.Item>
          <Grid.Item xs={12} sm={12} md={6} lg={6}>
            <WidgetChartCurrency
              id="interest-collected-per-month"
              title="Income"
              valueGetter={({ interest }) => interest}
              enabled={loaded >= 4}
              variant="secondary"
              onLoad={() => setLoaded((i) => i + 1)}
              onClick={() => navigate(pathTo('paymentReport'))}
            />
          </Grid.Item>
        </Grid>
      </Flex>
    </MainContent>
  )
}

export { Dashboard }
