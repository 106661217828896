import { useCallback, useState } from 'react'
import { SelectLoan } from 'admin/components/SelectLoan'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Modal } from 'components/Modal'
import { useThreadContext } from '../ThreadContext'

interface Props {
  onClose: () => void
}

const ModalLinkLoan = ({ onClose }: Props) => {
  const [loanId, setLoanId] = useState<string>()
  const { thread, updateThread, isUpdating } = useThreadContext()

  const handleLink = useCallback(() => {
    updateThread(
      { loans: [...thread.loans.map(({ id }) => id), loanId!] },
      {
        onSuccess: () => {
          onClose()
        },
      }
    )
  }, [updateThread, thread, loanId, onClose])

  return (
    <Modal title="Link to Loan" onClose={onClose}>
      <Flex stack>
        <Flex stack gap={6}>
          <div className="text-grey-800">Select a loan</div>
          <SelectLoan
            loanId={loanId}
            onChange={({ loanId }) => setLoanId(loanId)}
          />
        </Flex>
        <Flex justifyContent="flex-end" alignItems="center" gap={10}>
          <Button variant="tertiary" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleLink} loading={isUpdating} disabled={!loanId}>
            Link
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export { ModalLinkLoan }
