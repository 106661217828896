import { cloneElement, ReactElement, useCallback, useState } from 'react'
import { Formula } from 'components/Formula/Formula'
import { Icon, IconName } from 'components/Icon'

interface Props {
  error?: string
  singleLine?: boolean
  children: ReactElement
  formula: string
  variables?: string[]
  onFormulaChange: (formula: string) => void
  formattedValue: string
}

const FormulaWrapper = ({
  singleLine,
  error,
  formula,
  variables,
  onFormulaChange,
  formattedValue,
  children,
}: Props) => {
  const [isFormulaMode, setIsFormulaMode] = useState(false)
  const handleFormulaChange = useCallback(
    (formula: string) => {
      if (formula === '') {
        setIsFormulaMode(false)
      }
      onFormulaChange?.(formula === '=' ? '' : formula)
    },
    [onFormulaChange]
  )

  const formulaReadOnly = formula === '='

  return isFormulaMode && !!onFormulaChange ? (
    <Formula
      singleLine={singleLine}
      error={error}
      formula={formula}
      variables={variables}
      formattedValue={formattedValue}
      onFormulaChange={handleFormulaChange}
      onClose={() => setIsFormulaMode(false)}
    />
  ) : (
    <div className="relative">
      {cloneElement(children, {
        supportFormula: true,
        onKeyDown: (e) => {
          if (e.key === '=' && !formulaReadOnly) {
            setIsFormulaMode(true)
            e.preventDefault()
            e.stopPropagation()
          }
        },
        onFocus: () => {
          if (formula && !formulaReadOnly) {
            setIsFormulaMode(true)
          }
        },
      })}
      {formula && (
        <Icon
          name={IconName.formula}
          size="md"
          className="absolute right-2.5 top-2.5 text-grey-600 cursor-pointer"
          onClick={() => {
            if (!formulaReadOnly) {
              setIsFormulaMode(true)
            }
          }}
        />
      )}
    </div>
  )
}

export { FormulaWrapper }
