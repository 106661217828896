import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ModalLinkContact } from 'admin/pages/Thread/PanelsForLinking/ModalLinkContact'
import { useThreadContext } from 'admin/pages/Thread/ThreadContext'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Person } from 'types'

const LinkedContacts = () => {
  const navigate = useNavigate()
  const [isLinkingToContactModal, setIsLinkingToContactModal] = useState(false)
  const { thread } = useThreadContext()

  const handleContactClick = useCallback(
    (contact: Person) => {
      if (contact.isBorrower) {
        navigate(pathTo('borrower', contact.id))
      }
      if (contact.isInvestor) {
        navigate(pathTo('investor', contact.id))
      }
      if (contact.isVendor) {
        navigate(pathTo('vendor', contact.id))
      }
    },
    [navigate]
  )

  return (
    <div className="mt-3">
      <div className="uppercase text-[10px] text-grey-600 font-bold">
        in this conversation
      </div>
      {thread.people.length ? (
        <Flex
          stack
          gap={0}
          className="w-full mt-4 rounded border-solid border-[1px] border-grey-200"
        >
          {thread.people.map((contact) => (
            <Flex
              key={contact.id}
              gap={8}
              alignItems="center"
              justifyContent="space-between"
              onClick={() => handleContactClick(contact)}
              className="py-2.5 px-2 border-0 border-b last:border-b-0 border-grey-200 border-solid cursor-pointer"
            >
              <Flex gap={12} alignItems="center">
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  className="min-w-7 h-7 rounded border border-solid border-grey-200"
                >
                  <Icon
                    name={IconName.loanNavigation}
                    className="text-grey-600"
                  />
                </Flex>
                <div className="text-black-100">{contact.name}</div>
              </Flex>
              <Icon name={IconName.arrowRight} className="text-grey-600" />
            </Flex>
          ))}
        </Flex>
      ) : (
        <div className="mt-4 mb-5 text-grey-900 leading-[18px]">
          Find an existing contact to link to.
        </div>
      )}
      <Button
        variant="secondary"
        onClick={() => setIsLinkingToContactModal(true)}
        className="w-full mt-2.5"
      >
        Link to Contact
      </Button>
      {isLinkingToContactModal && (
        <ModalLinkContact onClose={() => setIsLinkingToContactModal(false)} />
      )}
    </div>
  )
}

export { LinkedContacts }
