import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { pathTo, pathToLoan } from 'admin/path-to'
import { Table } from 'components/Table'
import { Sort } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'

interface Props {
  data?: any[]
  sort?: Sort | undefined
  onSort?: (sort: Sort | string | undefined) => void
  loading?: boolean
}

function TableTax1098({ data = [], sort, onSort, loading }: Props) {
  const columns: ColumnDef<any>[] = useMemo(
    () => [
      {
        id: 'borrower',
        header: 'Borrower',
        size: 200,
        cell: ({ row }) => {
          return row.original.borrowerId ? (
            <NavLink
              key={row.original.borrowerId}
              to={pathTo('borrower', row.original.borrowerId)}
              className="link"
            >
              {row.original.borrowerName}
            </NavLink>
          ) : (
            '-'
          )
        },
      },
      {
        id: 'borrowerAddress',
        header: 'Borrower Address',
        size: 200,
        cell: ({ row }) => row.original.borrowerAddress as string,
      },
      {
        id: 'borrowerTaxIdType',
        header: 'ID Type',
        cell: ({ row }) => row.original.borrowerTaxIdType as string,
      },
      {
        id: 'borrowerTaxId',
        header: 'ID Number',
        size: 100,
        cell: ({ row }) => (row.original.borrowerTaxId ? '•••••••••' : '-'),
      },

      {
        id: 'loan',
        header: 'Loan Address',
        size: 200,
        cell: ({ row }) => {
          return row.original.loanId ? (
            <NavLink
              to={pathToLoan({
                id: row.original.loanId,
                status: row.original.loanStatus,
              })}
              className="link"
            >
              {row.original.loanAddress}
            </NavLink>
          ) : (
            '-'
          )
        },
      },
      {
        header: `Interest Paid`,
        accessorKey: 'interestPaid',
        cell: ({ getValue }) => formatUsd(getValue() as number),
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Outstanding Principal',
        accessorKey: 'principalBalance',
        cell: ({ getValue }) => formatUsd(getValue() as number),
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Origination Date',
        accessorKey: 'originationDate',
        cell: ({ getValue }) => formatDate(getValue() as string),
        size: 130,
      },
      {
        header: 'Properties',
        accessorKey: 'loanNumProperties',
      },
    ],
    []
  )

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      sort={sort}
      onSort={onSort}
    />
  )
}

export default TableTax1098
