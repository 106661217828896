import { get } from 'lodash'
import { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ImportEntity } from 'admin/components/ImportEntity'
import { TableInvestorTransactions } from 'admin/components/table/TableInvestorTransactions'
import { InvestorDetails } from 'admin/services/api/investors'
import { downloadInvestorTransactions } from 'admin/services/csv/download-investor-transactions'
import { Button } from 'components/Button'
import { Download } from 'components/Download'
import {
  Filter,
  filterValueToTableFilter,
  filterValueToUrl,
  IFilterConfig,
  IFilterValue,
  urlToFilterValue,
} from 'components/Filter'
import { LoadMore } from 'components/LoadMore'
import { ModalDelete } from 'components/Modal/Delete'
import { ModalInvestorPayment } from 'components/Modal/ModalInvestorPayment'
import { Panel } from 'components/Panel'
import { INVESTOR_TRANSACTION_IMPORT_BANNER } from 'constants/local-storage-keys'
import {
  useInvestorTransactions,
  useAddInvestorPayment,
  useUpdateInvestorPayment,
  useDeleteInvestorPayment,
} from 'hooks/use-investor-transactions'
import { usePagination } from 'hooks/use-pagination'
import { useSession } from 'hooks/use-session'
import { Transaction } from 'types'
import styles from '../Loan/styles.module.scss'
import { AlertFunding } from './AlertFunding'

interface Props {
  investor: InvestorDetails
}

const filterConfig: IFilterConfig[] = [
  {
    id: 'type',
    type: 'select',
    label: 'Type',
    options: [
      { value: 'Charge', label: 'Charge' },
      { value: 'DRIP', label: 'DRIP' },
      { value: 'Deposit', label: 'Deposit' },
      { value: 'Income', label: 'Income' },
      { value: 'Interest', label: 'Interest' },
      { value: 'Investment', label: 'Investment' },
      { value: 'Other', label: 'Other' },
      { value: 'Returned Capital', label: 'Returned Capital' },
      { value: 'Spread', label: 'Spread' },
      { value: 'Withdrawal', label: 'Withdrawal' },
    ],
  },
  {
    id: 'date',
    type: 'date',
    label: 'Date',
  },
  {
    id: 'amount',
    type: 'currency',
    label: 'Amount',
  },
]

function TabTransactions({ investor }: Props) {
  const { user } = useSession()
  const settings = get(user, 'client.settings', {
    autoInvestorAccounting: undefined,
  })

  const [searchParams, setSearchParams] = useSearchParams()
  const [deletingId, setDeletingId] = useState<string>()
  const [adding, setAdding] = useState<boolean>(false)
  const [editingTransaction, setEditingTransaction] = useState<Transaction>()
  const filtersValue = useMemo(
    () =>
      searchParams.get('filter')
        ? urlToFilterValue(searchParams.get('filter') as string)
        : [],
    [searchParams]
  )
  const { visibleItems, result, setPagination, resetPagination } =
    usePagination<Transaction>({
      property: 'transactions',
      useData: (params) =>
        useInvestorTransactions({
          ...params,
          filter: filterValueToTableFilter(filtersValue),
          id: investor.id,
        }),
    })
  const { mutate: add, isPending: isAdding } = useAddInvestorPayment(
    investor.id
  )
  const { mutate: update, isPending: isUpdating } = useUpdateInvestorPayment(
    investor.id
  )
  const { mutate: remove, isPending: deleting } = useDeleteInvestorPayment(
    investor.id
  )

  const showAddPayment = settings.autoInvestorAccounting !== 'Yes'

  const [alertData, setAlertData] = useState<string | null | boolean>(
    localStorage.getItem(INVESTOR_TRANSACTION_IMPORT_BANNER)
  )

  const handleFilterChange = (value: IFilterValue) => {
    setSearchParams({ filter: filterValueToUrl(value) }, { replace: true })
  }

  return (
    <div className={styles.tabContent}>
      <Panel
        title="Transactions"
        actionChildren={
          <>
            <Filter
              config={filterConfig}
              value={filtersValue}
              onApply={handleFilterChange}
            />
            <ImportEntity
              entityType="transaction"
              loanId={investor.id}
              setAlertData={() => setAlertData(true)}
            />
            <Download
              filename="transactions"
              download={() =>
                downloadInvestorTransactions(
                  investor.id,
                  filterValueToTableFilter(filtersValue)
                )
              }
            />
            {showAddPayment ? (
              <Button onClick={() => setAdding(true)}>Add Transaction</Button>
            ) : null}
          </>
        }
      >
        {alertData && (
          <AlertFunding alertData={alertData} setAlertData={setAlertData} />
        )}
        <TableInvestorTransactions
          data={visibleItems}
          loading={result.isPending}
          onEdit={(transaction) => setEditingTransaction(transaction)}
          onDelete={(paymentId) => setDeletingId(paymentId)}
        />
        <LoadMore
          loading={result.isPending}
          fetching={result.isFetching}
          count={visibleItems.length}
          meta={result.data?.meta}
          onLoadMore={setPagination}
        />
        {(adding || editingTransaction) && (
          <ModalInvestorPayment
            saving={isAdding || isUpdating}
            transaction={editingTransaction}
            onSave={(data) => {
              resetPagination()
              adding
                ? add(data, { onSuccess: () => setAdding(false) })
                : update(
                    {
                      transactionId: editingTransaction?.paymentId || '',
                      investorPayment: data,
                    },
                    {
                      onSuccess: () => setEditingTransaction(undefined),
                    }
                  )
            }}
            onCancel={() => {
              setAdding(false)
              setEditingTransaction(undefined)
            }}
          />
        )}
        {deletingId && (
          <ModalDelete
            resource="payment"
            loading={deleting}
            onDelete={() => {
              resetPagination()
              remove(deletingId, {
                onSuccess: () => setDeletingId(undefined),
              })
            }}
            onCancel={() => setDeletingId(undefined)}
          />
        )}
      </Panel>
    </div>
  )
}

export default TabTransactions
