import { without } from 'lodash'
import {
  useMemo,
  useEffect,
  useState,
  useCallback,
  SetStateAction,
  Dispatch,
} from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { usePayouts, usePayoutsCount } from 'admin/hooks/use-payout'
import { ModalProcessPayment } from 'admin/pages/Payouts/ModalProcessPayment'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { filterValueToTableFilter, urlToFilterValue } from 'components/Filter'
import { Flex } from 'components/Flex'
import { LoadMore } from 'components/LoadMore'
import { Panel } from 'components/Panel'
import { TableStickyFooter } from 'components/TableStickyFooter'
import { usePagination } from 'hooks/use-pagination'
import { Payout } from 'types'
import { formatUsd } from 'utils/currency'
import { pluralize } from 'utils/pluralize'
import { PanelTopBar } from './PanelTopBar'
import { TablePayouts } from './TablePayouts'

interface Props {
  search: string | undefined
  setSearch: Dispatch<SetStateAction<string | undefined>>
}

const PanelInQueue = ({ search, setSearch }: Props) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [payoutModal, setPayoutModal] = useState(false)
  const [checkboxes, setCheckboxes] = useState<{
    master: boolean
    ids: string[]
  }>({
    master: false,
    ids: [],
  })

  const handleCheckboxChange = useCallback(
    (id: string) => {
      if (id === 'master' && checkboxes.master) {
        setCheckboxes({ master: false, ids: [] })
      } else if (id === 'master' && !checkboxes.master) {
        setCheckboxes({ master: true, ids: [] })
      } else if (checkboxes.ids.includes(id)) {
        setCheckboxes({ ...checkboxes, ids: without(checkboxes.ids, id) })
      } else {
        setCheckboxes({ ...checkboxes, ids: [...checkboxes.ids, id] })
      }
    },
    [checkboxes]
  )

  const filtersValue = useMemo(
    () =>
      searchParams.get('filter')
        ? urlToFilterValue(searchParams.get('filter') as string)
        : [],
    [searchParams]
  )

  const {
    visibleItems,
    result,
    sort,
    setSort,
    setPagination,
    resetPagination,
  } = usePagination<Payout>({
    property: 'transactions',
    search,
    useData: (params) =>
      usePayouts({
        ...params,
        filter: {
          ...filterValueToTableFilter(filtersValue),
          completed: [false],
        },
      }),
  })
  const { data: payoutsSummary } = usePayoutsCount(
    {
      search,
      filter: filterValueToTableFilter(filtersValue),
      include: !checkboxes.master ? checkboxes.ids : [],
      exclude: checkboxes.master ? checkboxes.ids : [],
    },
    {
      enabled: checkboxes.master || !!checkboxes.ids.length,
    }
  )
  const selectedCount = useMemo(() => {
    if (checkboxes.master) {
      return payoutsSummary?.count || 0
    }
    return checkboxes.ids.length
  }, [checkboxes, payoutsSummary])

  useEffect(() => {
    resetPagination()
    setCheckboxes({ master: false, ids: [] })
  }, [filtersValue])

  useEffect(() => {
    setCheckboxes({ master: false, ids: [] })
  }, [search])

  return (
    <>
      <Panel className="pb-0">
        <PanelTopBar
          title="Queued for Payout"
          tab="ready"
          search={search}
          onSearch={setSearch}
        />
        <TablePayouts
          data={visibleItems}
          selectedCount={selectedCount}
          loading={result.isFetching}
          sort={sort}
          onSort={setSort}
          checkboxes={checkboxes}
          hasFilterUrl={!!filtersValue.length}
          onCheck={handleCheckboxChange}
        />
        <LoadMore
          className="pb-4"
          loading={result.isPending}
          fetching={result.isFetching}
          count={visibleItems.length || 0}
          meta={result.data?.meta}
          onLoadMore={setPagination}
        />
        {selectedCount > 0 && (
          <TableStickyFooter className="mx-0 px-2">
            <Flex
              alignItems="center"
              justifyContent="space-between"
              gap={8}
              className="w-full"
            >
              <div>
                {pluralize(selectedCount, 'payout')} selected:{' '}
                <b>{formatUsd(payoutsSummary?.amount)}</b>
              </div>
              <Button
                variant="primary"
                disabled={selectedCount === 0}
                onClick={() => setPayoutModal(true)}
              >
                Process Payouts
              </Button>
              <div className="invisible">
                {pluralize(selectedCount, 'payout')} selected:{' '}
                <b>{formatUsd(payoutsSummary?.amount)}</b>
              </div>
            </Flex>
          </TableStickyFooter>
        )}
      </Panel>
      {payoutModal && !!payoutsSummary && (
        <ModalProcessPayment
          transactions={payoutsSummary.transactions}
          onClose={(isComplete) => {
            setPayoutModal(false)
            if (isComplete) {
              navigate(pathTo('payoutsTab', 'completed'))
            }
          }}
        />
      )}
    </>
  )
}

export { PanelInQueue }
