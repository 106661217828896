import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { Flex } from 'components/Flex'
import { formatUsd } from 'utils/currency'

interface Props {
  loanAmount: number
  releasedAmount: number
  committedAmount: number
  amount: number
}

const EditFundingBar = ({
  loanAmount,
  releasedAmount,
  committedAmount,
  amount,
}: Props) => {
  const [initialAmount] = useState(amount)
  const netFunding = useMemo(() => {
    return amount - initialAmount
  }, [initialAmount, amount])

  const total = useMemo(
    () =>
      netFunding < 0
        ? loanAmount
        : Math.max(loanAmount, committedAmount + netFunding),
    [loanAmount, netFunding, committedAmount]
  )

  const unfunded = useMemo(
    () => loanAmount - committedAmount - netFunding,
    [loanAmount, committedAmount, netFunding]
  )

  const releasedPercent = useMemo(
    () => (releasedAmount / total) * 100,
    [releasedAmount, total]
  )

  const committedPercent = useMemo(
    () =>
      (Math.max(
        0,
        committedAmount - releasedAmount + (netFunding < 0 ? netFunding : 0)
      ) /
        total) *
      100,
    [netFunding, committedAmount, releasedAmount, total]
  )

  const amountPercent = useMemo(
    () =>
      ((netFunding > 0
        ? netFunding
        : committedAmount -
          releasedAmount -
          Math.max(committedAmount - releasedAmount + netFunding, 0)) /
        total) *
      100,
    [netFunding, total, committedAmount, releasedAmount]
  )

  const isOverfunded = total > loanAmount

  return (
    <Flex stack gap={10} className="normal-nums">
      <div>
        Loan amount:{' '}
        <span className="text-green-100 font-bold">
          {formatUsd(loanAmount, { showZero: true })}
        </span>
      </div>
      <Flex gap={0} className="h-2 bg-grey-100 rounded-[3px]">
        {releasedPercent > 0.1 && (
          <div
            style={{ width: `${releasedPercent}%` }}
            className="h-full bg-green-50 inline-block first:rounded-l-[3px]"
          />
        )}
        {committedPercent > 0.1 && (
          <div
            style={{ width: `${committedPercent}%` }}
            className="h-full bg-green-35 inline-block first:rounded-l-[3px]"
          />
        )}
        {amountPercent > 0.1 && (
          <div
            style={{ width: `${amountPercent}%` }}
            className={clsx(
              'h-full bg-grey-400 inline-block first:rounded-l-[3px]',
              isOverfunded && 'last:rounded-r-[3px]'
            )}
          />
        )}
      </Flex>
      <Flex flexWrap="wrap" gap={12}>
        <Flex gap={6} alignItems="center" className="text-sm">
          <div className="w-2.5 h-2.5 bg-green-50 rounded-[3px]"></div>
          {formatUsd(releasedAmount, {
            showZero: true,
          })}{' '}
          Released
        </Flex>
        <Flex gap={6} alignItems="center" className="text-sm">
          <div className="w-2.5 h-2.5 bg-green-35 rounded-[3px]"></div>
          {formatUsd(committedAmount, {
            showZero: true,
          })}{' '}
          Committed
        </Flex>
        <Flex gap={6} alignItems="center" className="text-sm">
          <div className="w-2.5 h-2.5 bg-grey-400 rounded-[3px]"></div>
          {formatUsd(netFunding, {
            showZero: true,
          })}{' '}
          Net Funding
        </Flex>
        <Flex gap={6} alignItems="center" className="text-sm">
          <div className="w-2.5 h-2.5 bg-grey-100 rounded-[3px]"></div>
          {formatUsd(unfunded, {
            showZero: true,
          })}{' '}
          Unfunded
        </Flex>
      </Flex>
    </Flex>
  )
}

export { EditFundingBar }
