import { capitalize } from 'lodash'
import { Checkbox } from 'components/Checkbox'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'

interface Props {
  checkboxes: {
    master: boolean
    ids: string[]
  }
  onChange: () => void
  onSelect: (value: 'all' | 'unread' | 'read') => void
}

const options: ('all' | 'unread' | 'read')[] = ['all', 'unread', 'read']

const ThreadsMainCheckbox = ({ checkboxes, onChange, onSelect }: Props) => {
  return (
    <EllipsesActions
      placement="bottom-start"
      trigger={
        <Flex className="bg-white-100 relative py-1 -my-1 pr-3.5 -mr-3.5">
          <Flex
            gap={8}
            alignItems="center"
            className="border border-solid border-grey-200 bg-grey-25 rounded px-2 h-8"
          >
            <Checkbox
              checked={checkboxes.master}
              indeterminate={!!checkboxes.ids.length}
              onClick={(e) => {
                e.stopPropagation()
              }}
              onChange={onChange}
            />
            <Icon name={IconName.arrowDownFilled} className="text-grey-600" />
          </Flex>
        </Flex>
      }
    >
      {options.map((value) => (
        <EllipsesActions.Item key={value} onSelect={() => onSelect(value)}>
          {capitalize(value)}
        </EllipsesActions.Item>
      ))}
    </EllipsesActions>
  )
}

export { ThreadsMainCheckbox }
