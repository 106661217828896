import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list'
import {
  $createHeadingNode,
  $isHeadingNode,
  HeadingTagType,
} from '@lexical/rich-text'
import { $setBlocksType } from '@lexical/selection'
import {
  $findMatchingParent,
  $getNearestNodeOfType,
  mergeRegister,
} from '@lexical/utils'
import clsx from 'clsx'
import {
  $createParagraphNode,
  $isRootOrShadowRoot,
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $isElementNode,
  ElementFormatType,
} from 'lexical'
import { useCallback, useEffect, useState } from 'react'
import styles from 'admin/components/InlineWysiwyg/styles.module.scss'
import DropDown, {
  DropDownItem,
} from 'admin/components/InlineWysiwyg/ui/Dropdown'
import { getSelectedNode } from 'admin/components/InlineWysiwyg/utils/getSelectedNode'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import type { LexicalEditor } from 'lexical'

const formatTypeToBlockName = {
  bullet: 'Bulleted List',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  h5: 'Heading 5',
  h6: 'Heading 6',
  number: 'Numbered List',
  paragraph: 'Normal',
}

const formatTypeToBlockIcon = {
  bullet: IconName.listUl,
  h1: IconName.textH1,
  h2: IconName.textH2,
  h3: IconName.textH3,
  h4: IconName.textH4,
  h5: IconName.textH5,
  h6: IconName.textH6,
  number: IconName.listOl,
  paragraph: IconName.textParagraph,
}

const alignTypeToBlockName = {
  justify: 'Justify Align',
  left: 'Left Align',
  center: 'Center Align',
  right: 'Right Align',
}

const alignTypeToBlockIcon = {
  justify: IconName.textAlignJustify,
  left: IconName.textAlignLeft,
  center: IconName.textAlignCenter,
  right: IconName.textAlignRight,
}

function dropDownActiveClass(active: boolean) {
  return active ? styles.dropdownItemActive : ''
}

function BlockAlignmentDropDown({
  editor,
  blockType,
}: {
  blockType: keyof typeof alignTypeToBlockName
  editor: LexicalEditor
}) {
  return (
    <DropDown
      buttonClassName={clsx(styles.toolbarItem, styles.blockControl)}
      buttonIcon={<Icon name={alignTypeToBlockIcon[blockType]} size="md" />}
      buttonAriaLabel="Formatting options for text style"
    >
      {Object.entries(alignTypeToBlockName).map(([key, value]) => (
        <DropDownItem
          key={key}
          className={clsx(
            styles.dropdownItem,
            dropDownActiveClass(blockType === key)
          )}
          onClick={() => {
            editor.dispatchCommand(
              FORMAT_ELEMENT_COMMAND,
              key as ElementFormatType
            )
          }}
        >
          <Icon name={IconName.textAlignJustify} size="md" />
          <span className={styles.dropdownItemText}>{value}</span>
        </DropDownItem>
      ))}
    </DropDown>
  )
}

function BlockFormatDropDown({
  editor,
  blockType,
}: {
  blockType: keyof typeof formatTypeToBlockName
  editor: LexicalEditor
}): JSX.Element {
  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode())
      }
    })
  }

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createHeadingNode(headingSize))
        }
      })
    }
  }

  const formatBulletList = () => {
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }

  return (
    <DropDown
      buttonClassName={clsx(styles.toolbarItem, styles.blockControl)}
      buttonIcon={<Icon name={formatTypeToBlockIcon[blockType]} size="md" />}
      buttonLabel={formatTypeToBlockName[blockType]}
      buttonAriaLabel="Formatting options for text style"
    >
      <DropDownItem
        className={clsx(
          styles.dropdownItem,
          dropDownActiveClass(blockType === 'paragraph')
        )}
        onClick={formatParagraph}
      >
        <Icon name={IconName.textParagraph} size="md" />
        <span className={styles.dropdownItemText}>Normal</span>
      </DropDownItem>
      <DropDownItem
        className={clsx(
          styles.dropdownItem,
          dropDownActiveClass(blockType === 'h1')
        )}
        onClick={() => formatHeading('h1')}
      >
        <Icon name={IconName.textH1} size="md" />
        <span className={styles.dropdownItemText}>Heading 1</span>
      </DropDownItem>
      <DropDownItem
        className={clsx(
          styles.dropdownItem,
          dropDownActiveClass(blockType === 'h2')
        )}
        onClick={() => formatHeading('h2')}
      >
        <Icon name={IconName.textH2} size="md" />
        <span className={styles.dropdownItemText}>Heading 2</span>
      </DropDownItem>
      <DropDownItem
        className={clsx(
          styles.dropdownItem,
          dropDownActiveClass(blockType === 'h3')
        )}
        onClick={() => formatHeading('h3')}
      >
        <Icon name={IconName.textH3} size="md" />
        <span className={styles.dropdownItemText}>Heading 3</span>
      </DropDownItem>
      <DropDownItem
        className={clsx(
          styles.dropdownItem,
          dropDownActiveClass(blockType === 'bullet')
        )}
        onClick={formatBulletList}
      >
        <Icon name={IconName.listUl} size="md" />
        <span className={styles.dropdownItemText}>Bullet List</span>
      </DropDownItem>
      <DropDownItem
        className={clsx(
          styles.dropdownItem,
          dropDownActiveClass(blockType === 'number')
        )}
        onClick={formatNumberedList}
      >
        <Icon name={IconName.listOl} size="md" />
        <span className={styles.dropdownItemText}>Numbered List</span>
      </DropDownItem>
    </DropDown>
  )
}

function ControlFontToolbar({ editor }: { editor: LexicalEditor }) {
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [formatType, setFormatType] =
    useState<keyof typeof formatTypeToBlockName>('paragraph')
  const [alignmentBlockType, setAlignmentBlockType] =
    useState<keyof typeof alignTypeToBlockName>('justify')

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent()
              return parent !== null && $isRootOrShadowRoot(parent)
            })

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow()
      }

      const elementKey = element.getKey()
      const elementDOM = editor.getElementByKey(elementKey)

      // Update text format
      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
      setIsUnderline(selection.hasFormat('underline'))

      const node = getSelectedNode(selection)
      const parent = node.getParent()

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode
          )
          const type = parentList
            ? parentList.getListType()
            : element.getListType()
          setFormatType(type as keyof typeof formatTypeToBlockName)
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType()
          if (type in formatTypeToBlockIcon) {
            setFormatType(type as keyof typeof formatTypeToBlockName)
          }
        }
      }

      if ($isElementNode(node) && node.getFormatType()) {
        const type = node.getFormatType()
        setAlignmentBlockType(type as keyof typeof alignTypeToBlockName)
      } else {
        const type = parent?.getFormatType() ? parent.getFormatType() : 'left'
        setAlignmentBlockType(type as keyof typeof alignTypeToBlockName)
      }
    }
  }, [editor])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar()
        })
      })
    )
  }, [editor, updateToolbar])

  return (
    <Flex
      className={clsx(
        'border border-solid border-grey-200 rounded bg-white-100 p-1'
      )}
      onMouseDown={(e) => e.preventDefault()}
    >
      <div className={styles.toolbarGroup}>
        <button
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
          }}
          className={clsx(styles.toolbarItem, isBold && styles.active)}
          aria-label="Format Bold"
        >
          <Icon
            name={IconName.textBold}
            size="md"
            className={`text-grey-600 ${isBold ? 'text-grey-800' : ''}`}
          />
        </button>
        <button
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
          }}
          className={clsx(styles.toolbarItem, isItalic && styles.active)}
          aria-label="Format Italics"
        >
          <Icon
            name={IconName.textItalic}
            size="md"
            className={`text-grey-600 ${isItalic ? 'text-grey-800' : ''}`}
          />
        </button>
        <button
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
          }}
          className={clsx(styles.toolbarItem, isUnderline && styles.active)}
          aria-label="Format Underline"
        >
          <Icon
            name={IconName.textUnderline}
            size="md"
            className={`text-grey-600 ${isUnderline ? 'text-grey-800' : ''}`}
          />
        </button>
        <BlockAlignmentDropDown
          blockType={alignmentBlockType}
          editor={editor}
        />
        <BlockFormatDropDown blockType={formatType} editor={editor} />
      </div>
    </Flex>
  )
}

export { ControlFontToolbar }
