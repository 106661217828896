import { camelCase, get, toString } from 'lodash'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useLoanStatement } from 'admin/hooks/use-loan-statement'
import { getBorrower, BorrowerDetails } from 'admin/services/api/borrowers'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import { Logo } from 'components/Logo'
import { useBorrower } from 'hooks/use-borrower'
import { useLoan } from 'hooks/use-loans'
import { useSession } from 'hooks/use-session'
import { TO_BORROWER_PAYMENT_TYPE } from 'services/api/payment'
import { formatAddress } from 'utils/address'
import { formatUsd } from 'utils/currency'
import { friendlyDate, formatDate } from 'utils/date'
import { subDecimal, sumDecimal } from 'utils/math'
import { formatPercent } from 'utils/percent'
import styles from './styles.module.scss'

function Statement() {
  const { user } = useSession()
  const settings = get(user, 'client.settings', {
    borrowerStatementFooter: undefined,
  })

  const footer = settings.borrowerStatementFooter

  const [isLogoLoaded, setIsLogoLoaded] = useState(false)
  const [additionalBorrowers, setAdditionalBorrowers] =
    useState<BorrowerDetails[]>()
  const { loanId, from, to } = useParams()
  const { data: statement } = useLoanStatement(
    loanId as string,
    `${from}/${to}`
  )
  const { data: loan } = useLoan({ id: loanId })
  const primaryBorrowerId = loan?.borrowers?.find(
    ({ borrow }) => borrow.primary
  )?.id
  const { data: borrower } = useBorrower(
    {
      id: primaryBorrowerId as string,
    },
    { enabled: !!primaryBorrowerId }
  )

  useEffect(() => {
    if (loan) {
      const notPrimaryBorrowers = loan.borrowers?.filter(
        ({ borrow }) => !borrow.primary
      )
      Promise.all(
        notPrimaryBorrowers.map((borrower) => getBorrower(borrower.id)) || []
      ).then((borrowers) => {
        setAdditionalBorrowers(borrowers)
      })
    }
  }, [loan])

  const getTrustName = (trustId: string) => {
    return (
      statement?.trusts.find(({ id }) => {
        return camelCase(id) === trustId
      })?.name || trustId
    )
  }

  const isLoaded =
    statement &&
    loan &&
    ((primaryBorrowerId && borrower) || !primaryBorrowerId) &&
    !!additionalBorrowers

  const trusts = (statement?.payments as any)
    ?.map(({ date, trusts }) =>
      trusts
        ? Object.entries(trusts).map(([id, amount]) => ({ id, amount, date }))
        : []
    )
    .flat()
    .filter(({ amount }) => (amount as number) > 0 || (amount as number) < 0)
    .map((trust) => ({ ...trust, name: getTrustName(trust.id) }))

  return isLoaded ? (
    <div className={styles.body} data-all-loaded={isLoaded && isLogoLoaded}>
      <div className="max-w-[700px] mx-auto pb-4 bg-white-100">
        <Flex gap={10} className="px-8 py-[30px]">
          <Flex className="flex-[45%]">
            <div>
              <Logo
                to="/"
                type="statement"
                logoClassName="h-8 break-inside-avoid bg-center"
                onLoad={() => setIsLogoLoaded(true)}
              />
            </div>
          </Flex>
          <Flex
            stack
            gap={6}
            alignItems="flex-end"
            justifyContent="space-between"
            className="flex-[55%] text-right"
          >
            <div className="py-0.5 text-[25px] print:text-[22px] font-light">
              Loan Statement
            </div>
            <div className="text-[12px] print:text-[9px] text-grey-700">
              Loan ID:{' '}
              <span className="text-grey-900 normal-nums">{loan.number}</span>
            </div>
            <div className="py-0.5 text-[12px] print:text-[9px] text-grey-700 normal-nums">
              {friendlyDate(statement.dateStart)} &mdash;{' '}
              {friendlyDate(statement.dateEnd)}
            </div>
          </Flex>
        </Flex>
        <div className="px-4">
          <Flex gap={44} className="bg-grey-50 px-4 pt-4 pb-5 rounded-[10px]">
            <Flex stack gap={0} className="flex-[65%] w-full pb-2.5">
              <div className="pb-2 border-0 border-b border-solid border-grey-300 text-grey-900 font-bold">
                Amount Due
              </div>

              <Flex
                stack
                gap={12}
                className="pb-3 border-0 border-b border-solid border-grey-300 text-[8px]"
              >
                <Flex
                  justifyContent="space-between"
                  className="font-bold mt-3 pb-0 text-grey-700  text-[11px] print:text-[8px]"
                >
                  <div>Due by {friendlyDate(statement.nextPayment.due)}</div>
                  <div>
                    {formatUsd(statement.due.due, {
                      showZero: true,
                    })}
                  </div>
                </Flex>
                {/* <div className="text-grey-700 leading-4">
                  $1,200.00 late charge after June 5, 2024 + $200 per day
                  overdue ???
                </div> */}
              </Flex>

              <div className="pt-4 text-grey-800 text-xs font-bold">
                Payment Breakdown
              </div>
              <Flex
                stack
                gap={8}
                className="mt-3 pb-4 text-grey-700  text-[11px] print:text-[8px]"
              >
                <Flex justifyContent="space-between">
                  <div>Principal</div>
                  <div>
                    {formatUsd(statement.nextPayment.principal, {
                      showZero: true,
                    })}
                  </div>
                </Flex>
                <Flex justifyContent="space-between">
                  <div>Interest</div>
                  <div>
                    {formatUsd(statement.nextPayment.interest, {
                      showZero: true,
                    })}
                  </div>
                </Flex>
                <Flex justifyContent="space-between">
                  <div>To Trusts</div>
                  <div>
                    {formatUsd(statement.nextPayment.toTrust, {
                      showZero: true,
                    })}
                  </div>
                </Flex>
                {!!statement.due.pastDueAmount && (
                  <Flex justifyContent="space-between">
                    <div>Past Due Payments</div>
                    <div>
                      {formatUsd(statement.due.pastDueAmount, {
                        showZero: true,
                      })}
                    </div>
                  </Flex>
                )}
                <Flex justifyContent="space-between">
                  <div>Charges Due</div>
                  <div>
                    {formatUsd(statement.due.charges, {
                      showZero: true,
                    })}
                  </div>
                </Flex>
                {/* <Flex justifyContent="space-between">
                  <div>Past Due Payments</div>
                  <div>???</div>
                </Flex>
                <Flex justifyContent="space-between">
                  <div>Charges Due</div>
                  <div>
                    {formatUsd(
                      statement.due.reduce((acc, { charges }) => {
                        return charges ? sumDecimal([acc, charges]) : acc
                      }, 0),
                      { showZero: true }
                    )}
                  </div>
                </Flex> */}
                {!!statement.due.pastDueCount && (
                  <Flex
                    gap={6}
                    alignItems="center"
                    className="mt-2 text-red-100 font-bold"
                  >
                    <Icon name={IconName.info} />
                    <div>
                      You have {statement.due.pastDueCount} past due payments
                    </div>
                  </Flex>
                )}
              </Flex>
              <Flex justifyContent="space-between">
                <div className="text-[13px] print:text-[10px] text-grey-900 font-bold">
                  Account Information{' '}
                </div>
                <div className="text-grey-700 normal-nums font-normal text-[11px] print:text-[8px] ">
                  {friendlyDate(statement.dateEnd)}
                </div>
              </Flex>
              <Flex
                stack
                gap={8}
                className="mt-3 pb-4 text-grey-700  text-[11px] print:text-[8px]"
              >
                <Flex justifyContent="space-between">
                  <div>Principal Balance</div>
                  <div>
                    {formatUsd(statement.principalBalance, { showZero: true })}
                  </div>
                </Flex>
                <Flex justifyContent="space-between">
                  <div>Interest Rate</div>
                  <div>
                    {formatPercent(statement.rate, { maxDecimals: 10 })}
                  </div>
                </Flex>
                <Flex justifyContent="space-between">
                  <div>Maturity Date</div>
                  <div>{friendlyDate(statement.dateMaturity as string)}</div>
                </Flex>
                <Flex justifyContent="space-between">
                  <div>Trust Balance</div>
                  <div>
                    {formatUsd(
                      statement.trusts.reduce((acc, { balance }) => {
                        return balance ? sumDecimal([acc, balance]) : acc
                      }, 0),
                      { showZero: true }
                    )}
                  </div>
                </Flex>
                {statement.trusts.map(({ id, name, balance }) => (
                  <Flex key={id} justifyContent="space-between">
                    <div className="ml-2.5">{name}</div>
                    <div>
                      {formatUsd(balance as number, { showZero: true })}
                    </div>
                  </Flex>
                ))}
                <Flex justifyContent="space-between">
                  <div>Outstanding Charges</div>
                  <div>
                    {formatUsd(statement.chargesBalance, { showZero: true })}
                  </div>
                </Flex>
                <Flex justifyContent="space-between">
                  <div>Unpaid Interest</div>
                  <div>
                    {formatUsd(statement.unpaidInterest, { showZero: true })}
                  </div>
                </Flex>
              </Flex>
            </Flex>

            <Flex stack gap={0} className="flex-[35%]">
              <div className="text-[13px] print:text-[10px] text-grey-900 font-bold">
                Borrower Info
              </div>

              {borrower && (
                <div className="mt-2 text-[11px] print:text-[8px]">
                  <div className="text-grey-700">Primary Borrower</div>
                  <div className="mt-1 text-grey-900">{borrower.name}</div>
                  <div className="mt-1 whitespace-pre-wrap leading-[14px] text-grey-900">
                    {borrower.addresses
                      ?.filter(({ type }) => type === 'primary')
                      .map((address) => (
                        <div key={address.id}>
                          {formatAddress(address, { multiline: true })}
                        </div>
                      ))}
                  </div>
                </div>
              )}

              {additionalBorrowers.map((additionalBorrower) => (
                <div
                  key={additionalBorrower.id}
                  className="mt-3 text-[11px] print:text-[8px]"
                >
                  <div className="text-grey-700">Additional Borrower</div>
                  <div className="mt-1 text-grey-900">
                    {additionalBorrower.name}
                  </div>
                  <div className="mt-1 whitespace-pre-wrap leading-[14px] text-grey-900">
                    {additionalBorrower.addresses
                      ?.filter(({ type }) => type === 'primary')
                      .map((address) => (
                        <div key={address.id}>
                          {formatAddress(address, { multiline: true })}
                        </div>
                      ))}
                  </div>
                </div>
              ))}

              <div className="mt-[18px] text-[13px] print:text-[10px] text-grey-900 font-bold">
                Collateral Information
              </div>
              {loan.addresses.map((address, i) => (
                <div key={address.id} className="mt-2">
                  <div className="text-grey-700 text-[11px] print:text-[8px]">{`Property address ${loan.addresses.length > 1 ? i + 1 : ''}`}</div>
                  <div className="mt-1 whitespace-pre-wrap leading-[14px] text-grey-900 text-[11px] print:text-[8px]">
                    {formatAddress(address, { multiline: true })}
                  </div>
                </div>
              ))}
            </Flex>
          </Flex>
        </div>

        <Flex stack className="my-7 px-4">
          <Flex stack gap={8} className="mb-4">
            <Flex
              alignItems="center"
              gap={8}
              className="text-[13px] print:text-[10px]"
            >
              <div className="text-grey-900 font-bold">
                Transaction Activity
              </div>
              <div className="text-grey-700 normal-nums">
                {friendlyDate(statement.dateStart)} &mdash;{' '}
                {friendlyDate(statement.dateEnd)}
              </div>
            </Flex>
            {statement.payments.length > 0 ? (
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Date Due</th>
                    <th>Description</th>
                    <th className="!text-right">Amount</th>
                    <th className="bg-grey-50 !text-right">Principal</th>
                    <th className="bg-grey-50 !text-right">Interest</th>
                    <th className="bg-grey-50 !text-right">Charges</th>
                    <th className="bg-grey-50 !text-right">Trust</th>
                  </tr>
                </thead>
                <tbody>
                  {statement.payments.map((payment) => (
                    <tr key={payment.id}>
                      <td>{formatDate(payment.date)}</td>
                      <td>{formatDate(payment.datePaidto as string)}</td>
                      <td>{payment.type}</td>
                      <td className=" !text-right">
                        {formatUsd(
                          payment.type === TO_BORROWER_PAYMENT_TYPE
                            ? sumDecimal([
                                payment.distribution.principal,
                                payment.distribution.interest,
                                payment.distribution.charges,
                                -1 * payment.source.trusts,
                                payment.distribution.spread,
                              ])
                            : sumDecimal([
                                payment.distribution.principal,
                                payment.distribution.interest,
                                payment.distribution.charges,
                                payment.distribution.trusts,
                                payment.distribution.spread,
                              ]),
                          { showZero: true }
                        )}
                      </td>
                      <td className="bg-grey-50 !text-right">
                        {formatUsd(payment.distribution.principal, {
                          showZero: true,
                        })}
                      </td>
                      <td className="bg-grey-50 !text-right">
                        {formatUsd(
                          sumDecimal([
                            payment.distribution.interest,
                            payment.distribution.spread,
                          ]),
                          {
                            showZero: true,
                          }
                        )}
                      </td>
                      <td className="bg-grey-50 !text-right">
                        {formatUsd(payment.distribution.charges, {
                          showZero: true,
                        })}
                      </td>
                      <td className="bg-grey-50 !text-right">
                        {formatUsd(
                          subDecimal(
                            payment.type === TO_BORROWER_PAYMENT_TYPE
                              ? 0
                              : payment.distribution.trusts,
                            payment.source.trusts
                          ),
                          {
                            showZero: true,
                          }
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className={styles.notable}>
                There was no activity in this period
              </div>
            )}
          </Flex>

          <Flex stack gap={8} className="mb-4">
            <Flex
              alignItems="center"
              gap={8}
              className="text-[13px] print:text-[10px]"
            >
              <div className="text-grey-900 font-bold">Charges</div>
              <div className="text-grey-700 normal-nums">
                {friendlyDate(statement.dateStart)} &mdash;{' '}
                {friendlyDate(statement.dateEnd)}
              </div>
            </Flex>
            {statement.charges.length > 0 ? (
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Due</th>
                    <th>Description</th>
                    <th className="!text-right">Original Amount</th>
                    <th className="!text-right">Outstanding Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {statement.charges.map((charge) => (
                    <tr key={charge.id}>
                      <td>{formatDate(charge.dateCharged)}</td>
                      <td>{formatDate(charge.dateDue as string)}</td>
                      <td>{charge.name}</td>
                      <td className="!text-right">
                        {formatUsd(charge.amount, { showZero: true })}
                      </td>
                      <td className="!text-right">
                        {formatUsd(charge.amountDue, { showZero: true })}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className={styles.notable}>
                There were no charges in this period
              </div>
            )}
          </Flex>

          <Flex stack gap={8}>
            <Flex
              alignItems="center"
              gap={8}
              className="text-[13px] print:text-[10px]"
            >
              <div className="text-grey-900 font-bold">Trust Activity</div>
              <div className="text-grey-700 normal-nums">
                {friendlyDate(statement.dateStart)} &mdash;{' '}
                {friendlyDate(statement.dateEnd)}
              </div>
            </Flex>
            {trusts.length > 0 ? (
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Trust</th>
                    <th className="!text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {trusts.map(({ id, name, date, amount }, i: number) => (
                    <tr key={id + toString(i)}>
                      <td>{formatDate(date as string)}</td>
                      <td>{name}</td>
                      <td className="!text-right">
                        {formatUsd(amount, { showZero: true })}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className={styles.notable}>
                There was no trust activity in this period
              </div>
            )}
          </Flex>
        </Flex>

        {footer && (
          <div className="px-4 text-xs font-normal">
            <p dangerouslySetInnerHTML={{ __html: footer }}></p>
          </div>
        )}
      </div>
    </div>
  ) : (
    <PageLoader />
  )
}

export { Statement }
