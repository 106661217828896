import { RawRule } from '@casl/ability'
import { isEqual } from 'lodash'
import { Flex } from 'components/Flex'
import { Radio } from 'components/Radio'

interface PermissionOption {
  label: string
  permission: RawRule[]
  checked: boolean
}

interface Props {
  id: string
  permissions: RawRule[]
  options: PermissionOption[]
  onSelection: ({ permissions }) => void
}

function PermissionChooser({ id, permissions, options, onSelection }: Props) {
  function handleSelection({ permission }) {
    const updatedPermissions = [
      ...options.reduce((acc, option) => {
        return acc.filter(
          (p) => !option.permission.some((p1) => isEqual(p, p1))
        )
      }, permissions || []),
      ...permission,
    ]

    onSelection({ permissions: updatedPermissions })
  }

  return (
    <Flex stack gap={6}>
      {options.map((option, i) => (
        <Radio
          key={`${id}-${i}`}
          name={id}
          defaultValue={option.label}
          label={option.label}
          checked={option.checked}
          onChange={() => handleSelection({ permission: option.permission })}
        />
      ))}
    </Flex>
  )
}

export { PermissionChooser }
export type { PermissionOption }
