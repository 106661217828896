import { PanelEntityBanking } from 'admin/components/PanelEntityBanking'
import { useUpdateLoanBanking } from 'admin/hooks/use-loan-banking'
import { PanelTrust } from 'borrower/pages/Loan/PanelTrust'
import { Grid } from 'components/Grid'
import { PanelLoanFields } from 'components/PanelLoanFields'
import { useSession } from 'hooks/use-session'
import { Loan } from 'types'
import { AlertModification } from './AlertModification'
import { PanelLoanPayment } from './PanelLoanPayment'
import { PanelLoanServicingTerms } from './PanelLoanServicingTerms'
import PanelSpreadAllocation from './PanelSpreadAllocation'
import PanelTranches from './PanelTranches'
import styles from './styles.module.scss'

interface Props {
  loan: Loan
}

function TabGeneral({ loan }: Props) {
  const { Can } = useSession()
  const { mutate: updateBanking, isPending: savingBanking } =
    useUpdateLoanBanking(loan.id)

  return (
    <div className={styles.tabContent}>
      <AlertModification loanId={loan.id} />
      <Grid gap={16}>
        <Grid.Item sm={6} className={styles.panels}>
          <PanelLoanServicingTerms loan={loan} />
          <PanelLoanFields
            loan={loan}
            title="Origination Details"
            sections={['Origination Details']}
            context="servicing"
            readonly
          />
          <PanelLoanFields
            loan={loan}
            title="Statistics"
            sections={['Statistics']}
            context="servicing"
          />
          <PanelLoanFields
            loan={loan}
            title="Collateral Summary"
            sections={['Collateral Summary']}
            context="servicing"
          />
        </Grid.Item>
        <Grid.Item sm={6} className={styles.panels}>
          <Can do="read" on="payments">
            <PanelLoanPayment loan={loan} />
          </Can>
          <PanelTranches loan={loan} />
          <Can do="read" on="banking">
            <PanelEntityBanking
              banking={loan.banking}
              lock={
                loan.lock && !['liquidated', 'closed'].includes(loan.status)
              }
              updateBanking={updateBanking}
              isSaving={savingBanking}
            />
          </Can>
          <PanelTrust loan={loan} alwaysShowEdit />
          <PanelSpreadAllocation loan={loan} />
        </Grid.Item>
      </Grid>
    </div>
  )
}

export default TabGeneral
