import { PageLoader } from 'components/LoaderOverlay'
import { SelectedPerson } from './ModalAttachDocs'

interface Props {
  selectedPerson: SelectedPerson
}

const ListOfContactDocuments = ({ selectedPerson }: Props) => {
  //   const { data: documents, isPending } = useContactDocuments({ // TODO add API for contact
  //     id: selectedPerson.id,
  //   })
  const isPending = false

  return (
    <>
      {isPending ? (
        <PageLoader />
      ) : (
        <div>
          <div>{selectedPerson.name}</div>
          <div className="text-xl font-bold">No API for contacts!!!</div>
        </div>
      )}
    </>
  )
}

export { ListOfContactDocuments }
