import { useCallback, useState } from 'react'
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { useThreads } from 'admin/hooks/use-threads'
import { useLoanContext } from 'admin/pages/Loan/LoanContext'
import { ThreadsMainCheckbox } from 'admin/pages/Threads/ThreadsMainCheckbox'
import { ThreadsTab } from 'admin/pages/Threads/ThreadsTab'
import { pathToLoan } from 'admin/path-to'
import { Button } from 'components/Button'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Search } from 'components/Search'
import { usePagination } from 'hooks/use-pagination'
import { IThreadListItem, IThreadTab } from 'types'

const tabs: { tab: 'all' | IThreadTab; name: string }[] = [
  {
    tab: 'all',
    name: 'All Mail',
  },
  {
    tab: 'inbox',
    name: 'Inbox',
  },
  {
    tab: 'draft',
    name: 'Draft',
  },
  {
    tab: 'sent',
    name: 'Sent',
  },
  {
    tab: 'archived',
    name: 'Archived',
  },
  {
    tab: 'trash',
    name: 'Trash',
  },
]

function TabMail() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { loan } = useLoanContext()
  const selectedTab = (searchParams.get('tab') as IThreadTab) || 'all'
  const [checkboxes, setCheckboxes] = useState<{
    master: boolean
    ids: string[]
  }>({
    master: false,
    ids: [],
  })
  const [search, setSearch] = useState<string>()
  const { visibleItems, result, isEmpty, setPagination } =
    usePagination<IThreadListItem>({
      property: 'threads',
      search,
      useData: (params) => useThreads(params),
    })
  const handleRowClick = useCallback(
    (row: IThreadListItem | undefined) => {
      navigate(pathToLoan(loan, 'loanTabEntityId', 'mail', row?.id as string))
    },
    [loan, navigate]
  )
  const handleComposeClick = useCallback(() => {
    navigate(pathToLoan(loan, 'loanTabEntityId', 'mail', 'new'))
  }, [navigate])
  const handleTabSelect = useCallback(
    (tab) =>
      navigate(
        {
          pathname: pathToLoan(loan, 'loanTab', 'mail'),
          search: createSearchParams({ tab }).toString(),
        },
        { replace: true }
      ),
    [navigate]
  )

  return (
    <Flex stack gap={0} className="-mt-2">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        className="w-full"
      >
        <Flex alignItems="center" className="h-12">
          <ThreadsMainCheckbox
            checkboxes={checkboxes}
            onChange={() =>
              setCheckboxes(({ master }) => ({
                master: !master,
                ids: [],
              }))
            }
            onSelect={() => {}}
          />
          <EllipsesActions
            placement="bottom-start"
            trigger={
              <Flex gap={8} alignItems="center">
                <Header>
                  {tabs.find(({ tab }) => tab === selectedTab)?.name}
                </Header>
                <Icon
                  name={IconName.arrowDownFilled}
                  className="text-grey-600"
                />
              </Flex>
            }
          >
            {tabs.map(({ tab, name }) => (
              <EllipsesActions.Item
                key={tab}
                onSelect={() => handleTabSelect(tab)}
              >
                {name}
              </EllipsesActions.Item>
            ))}
          </EllipsesActions>
        </Flex>
        <Flex
          justifyContent="flex-end"
          alignItems="center"
          className="bg-white-100 relative py-1 px-3 -my-1 -mx-3"
          gap={10}
        >
          <Search search={search} onSearch={setSearch} placeholder="Search" />
          <Button variant="primary" onClick={handleComposeClick}>
            <Icon name={IconName.draft} className="mr-1" />
            Compose
          </Button>
        </Flex>
      </Flex>
      <ThreadsTab
        tab="inbox"
        visibleItems={visibleItems}
        result={result}
        isEmpty={isEmpty}
        setPagination={setPagination}
        onRowClick={handleRowClick}
        checkboxes={checkboxes}
        onCheckboxesChange={setCheckboxes}
      />
    </Flex>
  )
}

export default TabMail
