import clsx from 'clsx'
import { useMemo } from 'react'
import { Flex } from 'components/Flex'
import { formatUsd } from 'utils/currency'

interface Props {
  budgetReleased: number
  budgetAmount: number
  amount: number
}

const MeterBarScopeOfWork = ({
  budgetReleased,
  budgetAmount,
  amount,
}: Props) => {
  const remainingAmount = useMemo(
    () => budgetAmount - budgetReleased - amount,
    [budgetAmount, budgetReleased, amount]
  )
  const total = useMemo(
    () => Math.max(budgetAmount, budgetReleased + amount),
    [budgetAmount, budgetReleased, amount]
  )
  const releasedPercent = useMemo(
    () => (budgetReleased / total) * 100,
    [budgetReleased, total]
  )
  const remainingPercent = useMemo(
    () => (remainingAmount / total) * 100,
    [remainingAmount, total]
  )
  const amountPercent = useMemo(() => (amount / total) * 100, [amount, total])

  return (
    <Flex stack gap={10} className="normal-nums">
      <Flex gap={0} className="h-2 bg-grey-100 rounded-[3px]">
        {releasedPercent > 0.1 && (
          <div
            style={{ width: `${releasedPercent}%` }}
            className="h-full bg-green-50 inline-block first:rounded-l-[3px]"
          />
        )}
        {amountPercent > 0.1 && (
          <div
            style={{ width: `${amountPercent}%` }}
            className={clsx(
              'h-full bg-grey-400 inline-block first:rounded-l-[3px]'
            )}
          />
        )}
        {remainingPercent > 0.1 && (
          <div
            style={{ width: `${remainingPercent}%` }}
            className="h-full bg-grey-100 inline-block first:rounded-l-[3px]"
          />
        )}
      </Flex>
      <Flex flexWrap="wrap" gap={12}>
        <Flex gap={6} alignItems="center" className="text-sm">
          <div className="w-2.5 h-2.5 bg-green-50 rounded-[3px]"></div>
          {formatUsd(budgetReleased, {
            showZero: true,
          })}{' '}
          Released
        </Flex>
        <Flex gap={6} alignItems="center" className="text-sm">
          <div className="w-2.5 h-2.5 bg-grey-400 rounded-[3px]"></div>
          {formatUsd(amount, {
            showZero: true,
          })}{' '}
          This Payment
        </Flex>
        <Flex gap={6} alignItems="center" className="text-sm">
          <div className="w-2.5 h-2.5 bg-grey-100 rounded-[3px]"></div>
          {formatUsd(remainingAmount, {
            showZero: true,
          })}{' '}
          Remaining Scope of Work
        </Flex>
      </Flex>
    </Flex>
  )
}

export { MeterBarScopeOfWork }
