import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathToLoan } from 'admin/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { useThreadContext } from '../ThreadContext'
import { ModalLinkLoan } from './ModalLinkLoan'

const LinkedLoans = () => {
  const navigate = useNavigate()
  const [isLinkingToLoanModal, setIsLinkingToLoanModal] = useState(false)
  const { thread } = useThreadContext()

  return (
    <div className="mt-3">
      <div className="uppercase text-[10px] text-grey-600 font-bold">
        linked loans
      </div>
      {thread.loans.length ? (
        <Flex
          stack
          gap={0}
          className="w-full mt-4 mb-2.5 rounded border-solid border-[1px] border-grey-200"
        >
          {thread.loans.map((loan) => (
            <Flex
              key={loan.id}
              gap={8}
              alignItems="center"
              justifyContent="space-between"
              onClick={() => navigate(pathToLoan(loan))}
              className="py-2.5 px-2 border-0 border-b last:border-b-0 border-grey-200 border-solid cursor-pointer"
            >
              <Flex gap={12} alignItems="center">
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  className="min-w-7 h-7 rounded border border-solid border-grey-200"
                >
                  <Icon
                    name={IconName.loanNavigation}
                    className="text-grey-600"
                  />
                </Flex>
                <div className="text-black-100">{loan.name}</div>
              </Flex>
              <Icon name={IconName.arrowRight} className="text-grey-600" />
            </Flex>
          ))}
        </Flex>
      ) : (
        <div className="mt-4 mb-5 text-grey-900 leading-[18px]">
          Find an existing loan to link to.
        </div>
      )}
      <Button
        variant="secondary"
        onClick={() => setIsLinkingToLoanModal(true)}
        className="w-full"
      >
        Link to Loan
      </Button>
      {isLinkingToLoanModal && (
        <ModalLinkLoan onClose={() => setIsLinkingToLoanModal(false)} />
      )}
    </div>
  )
}

export { LinkedLoans }
