import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { ListNode, ListItemNode } from '@lexical/list'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { HeadingNode } from '@lexical/rich-text'
import clsx from 'clsx'
import { $getRoot, LexicalEditor } from 'lexical'
import { uniqueId } from 'lodash'
import { useCallback, useState } from 'react'
import { ImageNode } from './nodes/ImageNode'
import { VariableNode } from './nodes/VariableNode'
import AutoLinkPlugin from './plugins/AutoLinkPlugin'
import { ContextPlugin } from './plugins/ContextPlugin'
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin'
import ImagePlugin from './plugins/ImagePlugin'
import { VariablePlugin } from './plugins/VariablePlugin'
import styles from './styles.module.scss'

const theme = {
  link: 'editorLink',
  image: 'editor-image',
  heading: {
    h1: 'editor-heading-h1',
    h2: 'editor-heading-h2',
    h3: 'editor-heading-h3',
    h4: 'editor-heading-h4',
    h5: 'editor-heading-h5',
  },
  list: {
    ol: 'editor-list-ol',
    ul: 'editor-list-ul',
  },
  text: {
    bold: 'editorTextBold',
    italic: 'editorTextItalic',
    underline: 'editorTextUnderline',
  },
}

interface Props {
  value: string
  placeholder?: string
  onChange?: (html: string) => void
  onCreate?: (editor: LexicalEditor) => void
}

function MailEditor({ value, placeholder, onChange, onCreate }: Props) {
  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null)
  const initialConfig = {
    namespace: uniqueId(),
    theme,
    onError: () => {},
    editorState: (editor) => {
      const parser = new DOMParser()
      const dom = parser.parseFromString(value || '<p></p>', 'text/html')
      const nodes = $generateNodesFromDOM(editor, dom)
      $getRoot().append(...nodes)
    },
    nodes: [
      AutoLinkNode,
      LinkNode,
      ListNode,
      ListItemNode,
      HeadingNode,
      VariableNode,
      ImageNode,
    ],
  }
  const handleChange = useCallback(
    (editorState, editor) => {
      editor.update(() => {
        const htmlString = $generateHtmlFromNodes(editor, null)
        onChange?.(htmlString)
      })
    },
    [onChange]
  )

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem)
    }
  }

  return (
    <LexicalComposer initialConfig={initialConfig}>
      {onCreate && <ContextPlugin onCreate={onCreate} />}
      <RichTextPlugin
        contentEditable={
          <div className={styles.editorContent} ref={onRef}>
            <ContentEditable
              data-placeholder={placeholder}
              className={clsx(styles.editorInput, styles.variableInput)}
            />
          </div>
        }
        placeholder={<></>}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <OnChangePlugin onChange={handleChange} />
      <HistoryPlugin />
      <AutoLinkPlugin />
      <LinkPlugin />
      <ListPlugin />
      <VariablePlugin />
      <ImagePlugin />
      <AutoFocusPlugin defaultSelection="rootEnd" />
      {floatingAnchorElem && (
        <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
      )}
    </LexicalComposer>
  )
}

export { MailEditor }
