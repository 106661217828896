import { request } from 'services/request'
import { IMail, IThreadRequest, Meta, Pagination } from 'types'
import { IThreadListItem, IThread } from 'types/thread'

const getThreads = async (params: {
  search?: string
  page?: Pagination
}): Promise<{ threads: IThreadListItem[]; meta: Meta }> => {
  const {
    data: { threads, pagination },
  } = await request.get('/thread', { params })
  return { threads, meta: pagination }
}

const getThread = async (threadId: string): Promise<IThread> => {
  const {
    data: { thread },
  } = await request.get(`/thread/${threadId}`)
  return thread
}

const addThread = async (payload: IThreadRequest): Promise<IThread> => {
  const {
    data: { thread },
  } = await request.post('/thread', payload)
  return thread
}

const addThreadMail = async ({
  threadId,
  replyTo,
  replyAllTo,
  forwardTo,
}: {
  threadId: string
  replyTo?: string
  replyAllTo?: string
  forwardTo?: string
}): Promise<{ mail: IMail; thread: IThread }> => {
  const {
    data: { mail, thread },
  } = await request.post(`/thread/${threadId}`, {
    replyTo,
    replyAllTo,
    forwardTo,
  })
  return { mail, thread }
}

const sendThreadMail = async ({
  mailId,
}: {
  mailId: string
}): Promise<{ mail: IMail; thread: IThread }> => {
  const {
    data: { mail, thread },
  } = await request.put(`/mail/${mailId}`)
  return { mail, thread }
}

const updateThread = async (payload: IThreadRequest): Promise<IThread> => {
  const {
    data: { thread },
  } = await request.patch(`/thread/${payload.id}`, payload)
  return thread
}

const updateThreadMail = async (payload: Partial<IMail>): Promise<IThread> => {
  const {
    data: { thread },
  } = await request.patch(`/mail/${payload.id}`, payload)
  return thread
}

const removeThread = async (id: string) => {
  return request.delete(`/thread/${id}`)
}

const removeThreadMail = async (id: string) => {
  return request.delete(`/mail/${id}`)
}

export {
  getThreads,
  getThread,
  addThread,
  addThreadMail,
  updateThread,
  removeThread,
  removeThreadMail,
  sendThreadMail,
  updateThreadMail,
}
