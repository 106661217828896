import clsx from 'clsx'
import { useCallback, useState } from 'react'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Modal } from 'components/Modal'
import { Section } from 'utils/loan-document-sections'
import { pluralize } from 'utils/pluralize'
import { ListOfContactDocuments } from './ListOfContactDocuments'
import { ListOfLoanDocuments } from './ListOfLoanDocuments'
import { ListOfPersons } from './ListOfPersons'
import { SelectedDocuments } from './SelectedDocuments'

const loans = [
  { id: '47638', name: 'Per diem test - JK' },
  { id: '2100930', name: 'JK Sept 27 Sharing Test' },
  { id: '7356237', name: 'Shaye Testing Jun 29 2024' },
]

const contacts = [
  { id: '47638', name: 'Rose McGowan' },
  { id: '9983381', name: 'Mark McGowan' },
  { id: '1873090', name: 'API loan Sold Testing Loan' },
]

interface Props {
  onClose: () => void
}

type Step = 'listOfPersons' | 'listOfDocuments' | 'selectedDocuments'

interface SelectedPerson {
  id: string
  name: string
  personType: 'loan' | 'contact'
}

interface PersonWithDocuments {
  person: SelectedPerson
  sections: Section[]
}

const ModalAttachDocs = ({ onClose }: Props) => {
  const [step, setStep] = useState<Step>('listOfPersons')
  const [selectedPerson, setSelectedPerson] = useState<SelectedPerson>()
  const [personWithDocuments, setPersonWithDocuments] = useState<
    PersonWithDocuments[]
  >([])
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([])

  const getTitle = useCallback(
    (step: Step) => {
      if (step === 'listOfPersons') {
        return 'Attach Documents'
      }
      if (step === 'listOfDocuments') {
        return (
          <Flex
            gap={12}
            alignItems="center"
            onClick={() => setStep('listOfPersons')}
            className="cursor-pointer"
          >
            <Icon name={IconName.arrowLeftLong} className="text-grey-600" />
            <div>{selectedPerson ? selectedPerson.name : 'Documents'}</div>
          </Flex>
        )
      }
      if (step === 'selectedDocuments') {
        return `${pluralize(selectedDocuments.length, 'Document')} Selected`
      }
    },
    [selectedPerson, selectedDocuments]
  )

  return (
    <Modal
      crossClose
      onClose={onClose}
      title={getTitle(step)}
      className="w-[564px]"
    >
      <Flex stack gap={28}>
        {step === 'listOfPersons' && (
          <ListOfPersons
            loans={loans}
            contacts={contacts}
            onSelectPerson={(person) => {
              setSelectedPerson(person)
              setStep('listOfDocuments')
            }}
          />
        )}
        {step === 'listOfDocuments' &&
          selectedPerson?.personType === 'loan' && (
            <ListOfLoanDocuments
              selectedPerson={selectedPerson}
              personWithDocuments={personWithDocuments}
              setPersonWithDocuments={setPersonWithDocuments}
              selectedDocuments={selectedDocuments}
              setSelectedDocuments={setSelectedDocuments}
            />
          )}
        {step === 'listOfDocuments' &&
          selectedPerson?.personType === 'contact' && (
            <ListOfContactDocuments selectedPerson={selectedPerson} />
          )}
        {step === 'selectedDocuments' && (
          <SelectedDocuments
            personWithDocuments={personWithDocuments}
            setPersonWithDocuments={setPersonWithDocuments}
            selectedDocuments={selectedDocuments}
            setSelectedDocuments={setSelectedDocuments}
          />
        )}

        <Flex alignItems="center" justifyContent="space-between">
          <Flex
            gap={8}
            alignItems="center"
            onClick={() =>
              (selectedDocuments.length || step === 'selectedDocuments') &&
              setStep(
                step === 'selectedDocuments'
                  ? 'listOfPersons'
                  : 'selectedDocuments'
              )
            }
            className={clsx(
              (selectedDocuments.length || step === 'selectedDocuments') &&
                'cursor-pointer'
            )}
          >
            <div className="text-grey-900 font-bold">
              {step === 'selectedDocuments' ? 'Hide Selected' : 'Show Selected'}
            </div>
            <div className="py-0.5 px-1.5 rounded-[5px] bg-purple-50 text-sm text-purple-300">
              {selectedDocuments.length}
            </div>
          </Flex>

          <Flex>
            <Button variant="tertiary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              // loading={loading}
              disabled={!selectedDocuments.length}
              onClick={() => console.log(personWithDocuments)}
            >
              Attach{' '}
              {`${selectedDocuments.length ? pluralize(selectedDocuments.length, 'File') : ''}`}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  )
}

export type { SelectedPerson, PersonWithDocuments }
export { ModalAttachDocs }
