import { LexicalEditor } from 'lexical'
import { DragEvent, useState } from 'react'
import { MailEditor } from 'admin/components/InlineWysiwyg/MailEditor'
import { DragDropFile } from 'components/DragDropFile'
import { IMail } from 'types'

interface Props {
  mail: IMail
  onInit?: (editor: LexicalEditor) => void
  onChange: (payload: Partial<IMail>) => void
}

const Body = ({ mail, onInit, onChange }: Props) => {
  const [isDragActive, setIsDragActive] = useState(false)

  const handleDrag = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      // setIsDragActive(true)
    } else if (e.type === 'dragleave') {
      setIsDragActive(false)
    }
  }

  const handleDrop = (files: FileList) => {
    setIsDragActive(false)
    console.log(files)
  }

  return (
    <div
      onDragEnter={handleDrag}
      className="relative h-80 mt-5 mx-6 overflow-auto"
    >
      <MailEditor
        value={mail.body}
        onCreate={onInit}
        onChange={(body) => onChange({ id: mail.id, body: body })}
      />
      {isDragActive && (
        <DragDropFile
          onLeave={() => setIsDragActive(false)}
          onDrop={handleDrop}
          className="rounded border-[2px] border-dashed border-purple-200 bg-purple-25"
        >
          <div className="text-5xl text-purple-300 leading-5">
            Drop to upload
          </div>
          <div className="text-lg text-grey-800 text-center">
            Upload files to your email by dropping them here
          </div>
        </DragDropFile>
      )}
    </div>
  )
}

export { Body }
