import { get, without } from 'lodash'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  useGetPayoutNachaFile,
  usePayouts,
  useReversePayout,
  useUpdatePayout,
} from 'admin/hooks/use-payout'
import { Button } from 'components/Button'
import { filterValueToTableFilter, urlToFilterValue } from 'components/Filter'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { LoadMore } from 'components/LoadMore'
import { ModalConfirm } from 'components/Modal/Confirm'
import { Panel } from 'components/Panel'
import { TableStickyFooter } from 'components/TableStickyFooter'
import { usePagination } from 'hooks/use-pagination'
import { useSession } from 'hooks/use-session'
import { Payout } from 'types'
import { formatUsd } from 'utils/currency'
import { message } from 'utils/message'
import { AlertPendingPayouts } from './AlertPendingPayouts'
import { PanelTopBar } from './PanelTopBar'
import { TablePayouts } from './TablePayouts'
import { getCompletedPayoutStatus } from './completed-helper'

interface Props {
  search: string | undefined
  setSearch: Dispatch<SetStateAction<string | undefined>>
}

const PanelProcessed = ({ search, setSearch }: Props) => {
  const [searchParams] = useSearchParams()
  const { mutate: updatePayout } = useUpdatePayout()
  const { mutate: getNachaFile } = useGetPayoutNachaFile()
  const { mutate: reversePayout, isPending: reversingPayout } =
    useReversePayout()
  const { user } = useSession()
  const settings = useMemo(
    () =>
      get(user, 'client.settings', {
        autoInvestorAccounting: undefined,
      }),
    [user]
  )

  const [reversePayoutItem, setReversePayoutItem] = useState<Payout>()
  const [modifiedTransactions, setModifiedTransactions] = useState<
    Record<string, boolean>
  >({})
  const [checkboxes, setCheckboxes] = useState<{
    master: boolean
    ids: string[]
  }>({
    master: false,
    ids: [],
  })

  const handleCheckboxChange = useCallback(
    (id: string) => {
      if (id === 'master' && checkboxes.master) {
        setCheckboxes({ master: false, ids: [] })
      } else if (id === 'master' && !checkboxes.master) {
        setCheckboxes({ master: true, ids: [] })
      } else if (checkboxes.ids.includes(id)) {
        setCheckboxes({ ...checkboxes, ids: without(checkboxes.ids, id) })
      } else {
        setCheckboxes({ ...checkboxes, ids: [...checkboxes.ids, id] })
      }
    },
    [checkboxes]
  )

  const filtersValue = useMemo(
    () =>
      searchParams.get('filter')
        ? urlToFilterValue(searchParams.get('filter') as string)
        : [],
    [searchParams]
  )

  const {
    visibleItems,
    result,
    sort,
    setSort,
    setPagination,
    updateItem,
    resetPagination,
  } = usePagination<Payout>({
    property: 'transactions',
    search,
    useData: (params) =>
      usePayouts({
        ...params,
        filter: {
          ...filterValueToTableFilter(filtersValue),
          completed: [true],
        },
      }),
  })

  const selectedCount = useMemo(() => {
    if (checkboxes.master) {
      return (
        (result?.data?.meta.total ?? 0) -
        (checkboxes.master ? checkboxes.ids.length : 0)
      )
    }

    return checkboxes.ids.length
  }, [checkboxes, result])

  const isPaidOutActive = useMemo(() => {
    return (
      checkboxes.master ||
      checkboxes.ids.some(
        (selectedId) =>
          getCompletedPayoutStatus(
            visibleItems.find(({ id }) => id === selectedId) as Payout,
            {
              autoInvestorAccounting: settings.autoInvestorAccounting === 'Yes',
            }
          ) === 'Pending'
      )
    )
  }, [checkboxes, visibleItems, settings])

  const handleMarkAsPaidOut = useCallback(() => {
    updatePayout(
      {
        search,
        filter: filterValueToTableFilter(filtersValue),
        include: !checkboxes.master ? checkboxes.ids : [],
        exclude: checkboxes.master ? checkboxes.ids : [],
        payout: { isPaidout: true },
      },
      {
        onSuccess: () => {
          message.success(`${checkboxes.ids.length} Marked as Paid Out`)
        },
      }
    )
    setCheckboxes({ master: false, ids: [] })
    setModifiedTransactions({})
  }, [updatePayout, search, filtersValue, checkboxes])

  const handleGenerateNacha = useCallback(() => {
    getNachaFile({
      search,
      filter: filterValueToTableFilter(filtersValue),
      include: !checkboxes.master ? checkboxes.ids : [],
      exclude: checkboxes.master ? checkboxes.ids : [],
    })
    setCheckboxes({ master: false, ids: [] })
  }, [getNachaFile, search, filtersValue, checkboxes])

  const handleStatusChange = useCallback(
    (id: string, isPaidout: boolean) => {
      updatePayout(
        { include: [id], payout: { isPaidout } },
        { onSuccess: (payouts) => updateItem(payouts[0]) }
      )
    },
    [updatePayout]
  )

  useEffect(() => {
    resetPagination()
  }, [filtersValue])

  useEffect(() => {
    resetPagination()
    setCheckboxes({ master: false, ids: [] })
  }, [filtersValue])

  useEffect(() => {
    setCheckboxes({ master: false, ids: [] })
  }, [search])

  return (
    <Panel className="pb-0">
      <PanelTopBar
        title="Processed Payouts"
        tab="completed"
        search={search}
        onSearch={setSearch}
      />
      <AlertPendingPayouts />
      <TablePayouts
        data={visibleItems}
        modifiedTransactions={modifiedTransactions}
        setModifiedTransactions={setModifiedTransactions}
        selectedCount={selectedCount}
        loading={result.isPending}
        sort={sort}
        onSort={setSort}
        onReverse={setReversePayoutItem}
        checkboxes={checkboxes}
        hasFilterUrl={!!filtersValue.length}
        onCheck={handleCheckboxChange}
        onChangeStatus={handleStatusChange}
        isCompleted
      />
      <LoadMore
        className="pb-4"
        loading={result.isPending}
        fetching={result.isFetching}
        count={visibleItems.length || 0}
        meta={result.data?.meta}
        onLoadMore={setPagination}
      />
      {selectedCount > 0 && (
        <TableStickyFooter className="mx-0 px-2">
          <Flex alignItems="center" gap={16}>
            <Button variant="secondary" onClick={handleGenerateNacha}>
              Download NACHA
            </Button>
            <Button disabled={!isPaidOutActive} onClick={handleMarkAsPaidOut}>
              <Icon name={IconName.approvedCircle} />
              Mark as Paid Out
            </Button>
          </Flex>
        </TableStickyFooter>
      )}
      {reversePayoutItem && (
        <ModalConfirm
          title="Reverse Payout"
          text={
            reversePayoutItem.banking ? (
              'Reversing this payout will remove the payment from your records and return it to the queue. Are you sure you want to continue?'
            ) : (
              <div>
                Reversing this payout will{' '}
                <b>
                  withdraw {formatUsd(reversePayoutItem.amount)} from the
                  recipient’s wallet
                </b>{' '}
                and return it to the queue. Are you sure you want to continue?
              </div>
            )
          }
          buttonText="Reverse Payment"
          color="negative"
          loading={reversingPayout}
          onConfirm={() => {
            setCheckboxes({
              ...checkboxes,
              ids: without(checkboxes.ids, reversePayoutItem.id),
            })
            reversePayout(reversePayoutItem.id, {
              onSuccess: () => {
                resetPagination()
                setReversePayoutItem(undefined)
              },
            })
          }}
          onCancel={() => setReversePayoutItem(undefined)}
        />
      )}
    </Panel>
  )
}

export { PanelProcessed }
