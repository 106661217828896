import { Flex } from 'components/Flex'
import { SelectedPerson } from './ModalAttachDocs'
import { PersonItem } from './PersonItem'

interface Props {
  loans: { id: string; name: string }[]
  contacts: { id: string; name: string }[]
  onSelectPerson: (person: SelectedPerson) => void
}

const ListOfPersons = ({ loans, contacts, onSelectPerson }: Props) => {
  return (
    <Flex stack gap={12}>
      <div className="text-sm text-grey-600 leading-[18px]">Linked Loans</div>
      <div>
        {loans.map((loan) => (
          <PersonItem
            key={loan.id}
            name={loan.name}
            onClickItem={() => onSelectPerson({ ...loan, personType: 'loan' })}
          />
        ))}
      </div>
      <div className="text-sm text-grey-600 leading-[18px]">Contacts</div>
      <div>
        {contacts.map((contact) => (
          <PersonItem
            key={contact.id}
            name={contact.name}
            onClickItem={() =>
              onSelectPerson({ ...contact, personType: 'contact' })
            }
          />
        ))}
      </div>
    </Flex>
  )
}

export { ListOfPersons }
