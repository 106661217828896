import clsx from 'clsx'
import React, { useMemo, useState } from 'react'
import { Checkbox } from 'components/Checkbox'
import { Flex } from 'components/Flex'
import { PageLoader } from 'components/LoaderOverlay'
import { useLoanDocuments } from 'hooks/use-loan-documents'
import { useLoan } from 'hooks/use-loans'
import { LoanDocument } from 'types'
import { friendlyMime, friendlySize } from 'utils/file'
import { Section, getLoanDocumentSections } from 'utils/loan-document-sections'
import { SelectedPerson, PersonWithDocuments } from './ModalAttachDocs'
import { PersonItem } from './PersonItem'

interface Props {
  selectedPerson: SelectedPerson
  personWithDocuments: PersonWithDocuments[]
  setPersonWithDocuments: (val: PersonWithDocuments[]) => void
  selectedDocuments: string[]
  setSelectedDocuments: (val: string[]) => void
}

const ListOfLoanDocuments = ({
  selectedPerson,
  personWithDocuments,
  setPersonWithDocuments,
  selectedDocuments,
  setSelectedDocuments,
}: Props) => {
  const [openSections, setOpenSections] = useState<string[]>([])
  const { data: loan, isPending: isLoanLoading } = useLoan({
    id: selectedPerson.id,
  })
  const { data: documents, isPending: isLoanDocumentsLoading } =
    useLoanDocuments({
      id: selectedPerson.id,
    })
  const sections = useMemo(
    () => getLoanDocumentSections({ loan, documents }),
    [loan, documents]
  )

  const handleOpenSection = (sectionName: string) => {
    if (openSections.includes(sectionName)) {
      setOpenSections((state) => state.filter((name) => name !== sectionName))
    } else {
      setOpenSections((state) => [...state, sectionName])
    }
  }

  const handleSelectDocument = (
    person: SelectedPerson,
    section: Section,
    document: LoanDocument
  ) => {
    if (selectedDocuments.includes(document.id)) {
      const updateDocumentsInSection = (
        sections: Section[],
        sectionName: string,
        documentId: string
      ) => {
        const updatedSections = sections.map((section) => {
          if (section.name !== sectionName) {
            return section
          }
          const updatedDocuments = section.documents.filter(
            (sectionDocument) => sectionDocument.id !== documentId
          )
          return { ...section, documents: updatedDocuments }
        })

        const filteredUpdatedSections = updatedSections.filter(
          (updatedSection) => !!updatedSection.documents.length
        )

        return filteredUpdatedSections
      }

      const updatedPersonWithDocuments = personWithDocuments.map(
        (personWithDocument) => {
          if (personWithDocument.person.id !== person.id) {
            return personWithDocument
          }
          const updatedSections = updateDocumentsInSection(
            personWithDocument.sections,
            section.name,
            document.id
          )
          return { ...personWithDocument, sections: updatedSections }
        }
      )

      const filteredUpdatedPersonWithDocuments =
        updatedPersonWithDocuments.filter(
          (updatedPersonWithDocument) =>
            !!updatedPersonWithDocument.sections.length
        )

      setPersonWithDocuments(filteredUpdatedPersonWithDocuments)
      setSelectedDocuments(
        selectedDocuments.filter((documentId) => documentId !== document.id)
      )
    } else {
      const updateDocumentsInSection = (
        sections: Section[],
        sectionName: string
      ) => {
        const hasSection = sections.some(
          (section) => section.name === sectionName
        )
        const updatedSections = hasSection
          ? sections.map((section) => {
              if (section.name !== sectionName) {
                return section
              }
              return { ...section, documents: [...section.documents, document] }
            })
          : [...sections, { ...section, documents: [document] }]

        return updatedSections
      }

      const hasPersonWithDocument = personWithDocuments.some(
        (personWithDocument) => personWithDocument.person.id === person.id
      )
      const updatedPersonWithDocuments = hasPersonWithDocument
        ? personWithDocuments.map((personWithDocument) => {
            if (personWithDocument.person.id !== person.id) {
              return personWithDocument
            }
            const updatedSections = updateDocumentsInSection(
              personWithDocument.sections,
              section.name
            )
            return { ...personWithDocument, sections: updatedSections }
          })
        : [
            ...personWithDocuments,
            { person, sections: [{ ...section, documents: [document] }] },
          ]

      setPersonWithDocuments(updatedPersonWithDocuments)
      setSelectedDocuments([...selectedDocuments, document.id])
    }
  }

  return (
    <>
      {isLoanLoading || isLoanDocumentsLoading ? (
        <PageLoader />
      ) : (
        <div>
          {sections.map((section) => (
            <React.Fragment key={section.name}>
              <PersonItem
                name={section.name}
                isSelected={openSections.includes(section.name)}
                onClickItem={() => handleOpenSection(section.name)}
              />
              {openSections.includes(section.name) && (
                <div className="border-0 border-b border-solid border-grey-100">
                  {section.documents.length ? (
                    section.documents.map((document) => (
                      <Flex
                        key={document.id}
                        gap={8}
                        alignItems="center"
                        onClick={() =>
                          document.size &&
                          handleSelectDocument(
                            selectedPerson,
                            section,
                            document
                          )
                        }
                        className={clsx(
                          'py-2.5 pr-2 pl-3 text-grey-700 leading-5',
                          document.size &&
                            !selectedDocuments.includes(document.id) &&
                            'cursor-pointer hover:bg-grey-50 hover:text-grey-800',
                          selectedDocuments.includes(document.id) &&
                            'cursor-pointer bg-purple-50 hover:text-grey-800',
                          !document.size && '!text-grey-500'
                        )}
                      >
                        <Checkbox
                          disabled={!document.size}
                          checked={selectedDocuments.includes(document.id)}
                          onChange={() => {}}
                        />
                        <div>{document.name}</div>
                        {document.size && (
                          <div className="text-sm text-grey-700">{`${friendlyMime(document.type || '')} - ${friendlySize(document.size)}`}</div>
                        )}
                      </Flex>
                    ))
                  ) : (
                    <div className="py-2.5 pr-2 pl-3 text-grey-700 leading-5">
                      No documents
                    </div>
                  )}
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  )
}

export { ListOfLoanDocuments }
