import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import {
  useRoles,
  useAddRole,
  useUpdateRole,
  useRemoveRole,
} from 'admin/hooks/use-roles'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { ModalDelete } from 'components/Modal/Delete'
import { Panel } from 'components/Panel'
import { Search } from 'components/Search'
import { Role } from 'types'
import { ModalRole } from './ModalRole'
import { TableRoles } from './TableRoles'

function Roles() {
  const navigate = useNavigate()
  const [isRoleModalVisible, setIsRoleModalVisible] = useState(false)
  const [editingId, setEditingId] = useState<string | null>(null)
  const [removingId, setRemovingId] = useState<string | null>(null)
  const [search, setSearch] = useState<string>()
  const { data, isPending } = useRoles()
  const { mutate: add, isPending: isAdding } = useAddRole()
  const { mutate: update, isPending: isUpdating } = useUpdateRole()
  const { mutate: remove, isPending: isRemoving } = useRemoveRole()

  const visibleRoles = useMemo(
    () =>
      data?.filter((role) => {
        return search
          ? role.name.toLowerCase().includes(search.toLowerCase())
          : true
      }),
    [data, search]
  )

  const handleSubmit = useCallback(
    (role: Partial<Role>) => {
      const options = {
        onSuccess: () => {
          setEditingId(null)
          setIsRoleModalVisible(false)
        },
      }
      if (editingId) {
        update({ id: editingId, ...role }, options)
      } else {
        add(role, options)
      }
    },
    [editingId, add, update]
  )

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          breadcrumbs={{ title: 'Company Settings', link: pathTo('settings') }}
          title="Roles & Permissions"
        />
        <div className="max-w-panel">
          <Panel
            actionChildren={
              <>
                <Search search={search} onSearch={setSearch} />
                <Button
                  variant="primary"
                  onClick={() => setIsRoleModalVisible(true)}
                >
                  Add Role
                </Button>
              </>
            }
          >
            <TableRoles
              data={visibleRoles}
              loading={isPending}
              onEdit={(id) => {
                navigate(pathTo('settingsRole', id))
              }}
              onDelete={(id) => setRemovingId(id)}
            />
          </Panel>
        </div>
        {isRoleModalVisible && (
          <ModalRole
            role={data?.find((role) => role.id === editingId)}
            saving={isAdding || isUpdating}
            onSubmit={handleSubmit}
            onCancel={() => {
              setEditingId(null)
              setIsRoleModalVisible(false)
            }}
          />
        )}
        {removingId && (
          <ModalDelete
            resource="member"
            loading={isRemoving}
            onDelete={() =>
              remove(
                { roleId: removingId },
                {
                  onSuccess: () => {
                    setRemovingId(null)
                  },
                }
              )
            }
            onCancel={() => setRemovingId(null)}
          />
        )}
        {}
      </Flex>
    </MainContent>
  )
}

export { Roles }
