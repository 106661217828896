import { getInvestorTransactions } from 'services/api/investor-payment'
import { Row, Transaction } from 'types'
import { getFundingType } from 'utils/loan-transactions'

const downloadInvestorTransactions = async (
  investorId: string,
  filter
): Promise<Row[]> => {
  const { transactions } = await getInvestorTransactions({
    id: investorId,
    filter,
  })

  return transactions.map((transaction: Transaction) => ({
    Date: transaction.date,
    Type: getFundingType(transaction),
    Reference:
      transaction.loan?.name ||
      transaction.fund?.name ||
      transaction.payment?.description ||
      '',
    Amount: transaction.amount,
    Balance: transaction.balance,
  }))
}

export { downloadInvestorTransactions }
