import React from 'react'
import { EmptyView } from 'components/EmptyView'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { IThreadTab } from 'types'

interface Props {
  tab: IThreadTab
}

const icon = {
  inbox: IconName.envelope,
  draft: IconName.draftThread,
  sent: IconName.sendThread,
  archived: IconName.archive,
  trash: IconName.delete,
}

const title = {
  inbox: 'Still waiting for mail',
  draft: 'No drafts in sight',
  sent: 'Send something',
  archived: 'Nothing archived',
  trash: 'Your trash is empty',
}

const description = {
  inbox: 'You haven’t received any messages.',
  draft: 'Hit compose to start a new message.',
  sent: 'You haven’t sent any messages... yet.',
  archived:
    'This is where you’ll find all your messages that have been archived.',
  trash: 'You haven’t moved any messages to trash yet',
}

export const Empty = ({ tab }: Props) => {
  return (
    <EmptyView className="mt-5">
      <EmptyView.Icon
        icon={
          <Flex
            justifyContent="center"
            alignItems="center"
            className={`h-20 w-20 bg-blueberry-25 rounded-[20px]`}
          >
            <Icon name={icon[tab]} className="text-blueberry-200 h-12 w-12" />
          </Flex>
        }
      />
      <EmptyView.Title title={title[tab]} />
      <EmptyView.Description description={description[tab]} />
    </EmptyView>
  )
}
