const options = [
  {
    name: 'Dashboard',
    options: [
      {
        label: 'On',
        permission: [{ subject: 'dashboard', action: 'read' }],
      },
      {
        label: 'Off',
        permission: [{ subject: 'dashboard', action: 'read', inverted: true }],
      },
    ],
  },
  {
    name: 'Tasks',
    options: [
      {
        label: 'All',
        permission: [{ subject: 'tasks', action: 'read' }],
      },
      {
        label: 'Assigned',
        permission: [{ subject: 'tasks', action: 'read', inverted: true }],
      },
    ],
  },
  {
    name: 'Borrowers',
    options: [
      {
        label: 'On',
        permission: [{ subject: 'borrowers', action: 'read' }],
      },
      {
        label: 'Off',
        permission: [{ subject: 'borrowers', action: 'read', inverted: true }],
      },
    ],
  },
  {
    name: 'Investors',
    options: [
      { label: 'All', permission: [{ subject: 'investors', action: 'read' }] },
      {
        label: 'None',
        permission: [
          { subject: 'investors', action: 'read', inverted: true },
          { subject: 'offerings', action: 'read', inverted: true },
          { subject: 'payouts', action: 'read', inverted: true },
          { subject: 'funds', action: 'read', inverted: true },
        ],
      },
    ],
  },
  {
    name: 'Vendors',
    options: [
      {
        label: 'On',
        permission: [{ subject: 'vendors', action: 'read' }],
      },
      {
        label: 'Off',
        permission: [{ subject: 'vendors', action: 'read', inverted: true }],
      },
    ],
  },
  {
    name: 'Quotes',
    options: [
      {
        label: 'Full Access',
        permission: [
          { subject: 'quotes', action: 'read' },
          { subject: 'quote-sizer', action: 'read' },
        ],
      },
      {
        label: 'Limited Access (no sizers)',
        permission: [
          { subject: 'quotes', action: 'read' },
          { subject: 'quote-sizer', action: 'read', inverted: true },
        ],
      },
      {
        label: 'Off',
        permission: [
          { subject: 'quotes', action: 'read', inverted: true },
          { subject: 'quote-sizer', action: 'read', inverted: true },
        ],
      },
    ],
  },
  {
    name: 'Pipeline',
    options: [
      {
        label: 'On',
        permission: [{ subject: 'pipeline', action: 'read' }],
      },
      {
        label: 'Off',
        permission: [
          { subject: 'pipeline', action: 'read', inverted: true },
          { subject: 'quotes', action: 'read', inverted: true },
        ],
      },
    ],
  },
  {
    name: 'Servicing',
    options: [
      {
        label: 'On',
        permission: [{ subject: 'servicing', action: 'read' }],
      },
      {
        label: 'Off',
        permission: [{ subject: 'servicing', action: 'read', inverted: true }],
      },
    ],
  },
  {
    name: 'Payments',
    options: [
      {
        label: 'On',
        permission: [{ subject: 'payments', action: 'read' }],
      },
      {
        label: 'Off',
        permission: [{ subject: 'payments', action: 'read', inverted: true }],
      },
    ],
  },
  {
    name: 'Payouts',
    options: [
      {
        label: 'On',
        permission: [{ subject: 'payouts', action: 'read' }],
      },
      {
        label: 'Off',
        permission: [{ subject: 'payouts', action: 'read', inverted: true }],
      },
    ],
  },
  {
    name: 'Reports',
    options: [
      {
        label: 'On',
        permission: [{ subject: 'reports', action: 'read' }],
      },
      {
        label: 'Off',
        permission: [{ subject: 'reports', action: 'read', inverted: true }],
      },
    ],
  },
  {
    name: 'Funds',
    options: [
      {
        label: 'On',
        permission: [{ subject: 'funds', action: 'read' }],
      },
      {
        label: 'Off',
        permission: [{ subject: 'funds', action: 'read', inverted: true }],
      },
    ],
  },
  {
    name: 'Offerings',
    options: [
      {
        label: 'On',
        permission: [{ subject: 'offerings', action: 'read' }],
      },
      {
        label: 'Off',
        permission: [{ subject: 'offerings', action: 'read', inverted: true }],
      },
    ],
  },
  {
    name: 'Banking',
    options: [
      {
        label: 'On',
        permission: [{ subject: 'banking', action: 'read' }],
      },
      {
        label: 'Off',
        permission: [{ subject: 'banking', action: 'read', inverted: true }],
      },
    ],
  },
  {
    name: 'Loan Settings',
    options: [
      {
        label: 'On',
        permission: [{ subject: 'loan-settings', action: 'read' }],
      },
      {
        label: 'Off',
        permission: [
          { subject: 'loan-settings', action: 'read', inverted: true },
        ],
      },
    ],
  },
  {
    name: 'Communications',
    options: [
      {
        label: 'On',
        permission: [{ subject: 'communications', action: 'read' }],
      },
      {
        label: 'Off',
        permission: [
          { subject: 'communications', action: 'read', inverted: true },
        ],
      },
    ],
  },
  {
    name: 'Change Log',
    options: [
      {
        label: 'On',
        permission: [{ subject: 'change-log', action: 'read' }],
      },
      {
        label: 'Off',
        permission: [{ subject: 'change-log', action: 'read', inverted: true }],
      },
    ],
  },
]

export { options }
