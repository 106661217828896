import { UseQueryResult } from '@tanstack/react-query'
import { without } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { LoadMore } from 'components/LoadMore'
import { TableStickyFooter } from 'components/TableStickyFooter'
import { IThreadListItem, IThreadTab, Meta, Pagination } from 'types'
import { Empty } from './Empty'
import { ThreadsTable } from './ThreadsTable'

interface Props {
  tab: IThreadTab
  visibleItems: IThreadListItem[]
  result: UseQueryResult<{ threads: IThreadListItem[]; meta: Meta }>
  isEmpty: boolean
  setPagination: (pagination: Pagination) => void
  onRowClick: (row: IThreadListItem | undefined) => void
  checkboxes: { master: boolean; ids: string[] }
  onCheckboxesChange: (data: { master: boolean; ids: string[] }) => void
}

function ThreadsTab({
  tab,
  visibleItems,
  result,
  isEmpty,
  setPagination,
  onRowClick,
  checkboxes,
  onCheckboxesChange,
}: Props) {
  const selectedCount = useMemo(() => {
    if (checkboxes.master) {
      return result.data?.meta.total || 0
    }
    return checkboxes.ids.length
  }, [checkboxes, result])

  const handleCheckboxChange = useCallback(
    (id: string) => {
      console.log('handleCheckboxChange', id)
      if (id === 'master' && checkboxes.master) {
        onCheckboxesChange({ master: false, ids: [] })
      } else if (id === 'master' && !checkboxes.master) {
        onCheckboxesChange({ master: true, ids: [] })
      } else if (checkboxes.ids.includes(id)) {
        onCheckboxesChange({ ...checkboxes, ids: without(checkboxes.ids, id) })
      } else {
        onCheckboxesChange({ ...checkboxes, ids: [...checkboxes.ids, id] })
      }
    },
    [checkboxes]
  )

  return (
    <>
      {isEmpty ? (
        <Empty tab={tab} />
      ) : (
        <div className="pb-4">
          <ThreadsTable
            data={visibleItems}
            loading={result.isLoading}
            onCheck={handleCheckboxChange}
            checkboxes={checkboxes}
            onRowClick={onRowClick}
          />
          <LoadMore
            loading={result.isPending}
            count={visibleItems.length}
            meta={result.data?.meta}
            onLoadMore={setPagination}
            fetching={result.isFetching}
          />
        </div>
      )}
      {selectedCount > 0 && (
        <TableStickyFooter className="-mx-8">
          <Flex
            justifyContent="center"
            alignItems="center"
            className="w-full relative"
          >
            <div className="absolute left-3">{selectedCount} Selected</div>
            <Flex alignItems="center" justifyContent="center" gap={10}>
              <Button
                variant="secondary"
                onClick={() => {
                  console.log('Archive')
                }}
              >
                <Icon
                  name={IconName.markAsUnread}
                  className="mr-1 text-grey-600"
                />
                Mark as unread
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  console.log('Archive')
                }}
              >
                <Icon name={IconName.archive} className="mr-1 text-grey-600" />
                Archive
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  console.log('Delete')
                }}
              >
                <Icon name={IconName.delete} className="mr-1 text-grey-600" />
                Delete
              </Button>
            </Flex>
          </Flex>
        </TableStickyFooter>
      )}
    </>
  )
}

export { ThreadsTab }
