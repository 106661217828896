import {
  useQuery,
  keepPreviousData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { KEY_THREADS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Pagination } from 'types'
import {
  getThreads,
  getThread,
  addThread,
  updateThread,
  removeThread,
  addThreadMail,
  removeThreadMail,
  sendThreadMail,
  updateThreadMail,
} from '../services/api/threads'

const useThreads = ({
  search,
  pagination,
}: { search?: string; pagination?: Pagination } = {}) => {
  return useQuery({
    queryKey: [KEY_THREADS, search, pagination],
    queryFn: () => getThreads({ search, page: pagination }),
    placeholderData: keepPreviousData,
  })
}

const useThread = ({ id }: { id: string }) => {
  return useQuery({
    queryKey: [KEY_THREADS, id],
    queryFn: () => getThread(id),
  })
}

const useAddThread = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addThread,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_THREADS] })
    },
    onError: handleErrorResponse,
  })
}

const useAddThreadMail = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addThreadMail,
    onSuccess: ({ thread }) => {
      queryClient.setQueryData([KEY_THREADS, thread.id], thread)
    },
    onError: handleErrorResponse,
  })
}

const useSendThreadMail = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: sendThreadMail,
    onSuccess: ({ thread }) => {
      queryClient.setQueryData([KEY_THREADS, thread.id], thread)
    },
    onError: handleErrorResponse,
  })
}

const useUpdateThread = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateThread,
    onSuccess: (thread) => {
      queryClient.invalidateQueries({ queryKey: [KEY_THREADS, thread.id] })
    },
    onError: handleErrorResponse,
  })
}

const useUpdateThreadMail = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateThreadMail,
    onSuccess: (thread) => {
      queryClient.invalidateQueries({ queryKey: [KEY_THREADS, thread.id] })
    },
    onError: handleErrorResponse,
  })
}

const useRemoveThread = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: removeThread,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_THREADS] })
    },
    onError: handleErrorResponse,
  })
}

const useRemoveThreadMail = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: removeThreadMail,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_THREADS] })
    },
    onError: handleErrorResponse,
  })
}

export {
  useThreads,
  useThread,
  useAddThread,
  useAddThreadMail,
  useUpdateThread,
  useRemoveThread,
  useRemoveThreadMail,
  useSendThreadMail,
  useUpdateThreadMail,
}
