import { ColumnDef } from '@tanstack/react-table'
import { isSameDay } from 'date-fns'
import { useMemo } from 'react'
import { Checkbox } from 'components/Checkbox'
import { Flex } from 'components/Flex'
import { Table } from 'components/Table'
import { IThreadListItem } from 'types/thread'
import { friendlyDateTime } from 'utils/date'
import styles from './styles.module.scss'

interface Props {
  data: IThreadListItem[]
  loading: boolean
  checkboxes: {
    master: boolean
    ids: string[]
  }
  onCheck: (id: string) => void
  onRowClick: (row?: IThreadListItem) => void
}
function ThreadsTable({
  data = [],
  loading = false,
  checkboxes,
  onCheck,
  onRowClick,
}: Props) {
  const columns: ColumnDef<IThreadListItem>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        size: 40,
        cell: ({ row }) => {
          const { id } = row.original

          return (
            <Checkbox
              checked={
                (checkboxes.master && !checkboxes.ids.includes(id)) ||
                (!checkboxes.master && checkboxes.ids.includes(id))
              }
              onChange={() => onCheck(id)}
              onClick={(e) => e.stopPropagation()}
            />
          )
        },
      },
      {
        accessorKey: 'name',
        size: 180,
        cell: ({ row }) => {
          return (
            <div className="text-grey-900 font-bold overflow-hidden text-ellipsis whitespace-nowrap">
              {row.original.from?.name}
            </div>
          )
        },
      },
      {
        accessorKey: 'text',
        cell: ({ row }) => {
          const { subject, body } = row.original

          return (
            <Flex>
              <div className="text-grey-900 font-bold truncate max-w-[35%]">
                {subject}
              </div>
              <div className="text-grey-700 truncate">{body}</div>
            </Flex>
          )
        },
      },
      {
        accessorKey: 'date',
        size: 100,
        meta: {
          align: 'right',
        },
        cell: ({ row }) => {
          const date = row.original.date

          return (
            <div className="text-grey-900 font-bold">
              {isSameDay(date, Date.now())
                ? friendlyDateTime(date, 'h:mm a')
                : friendlyDateTime(date, 'MMM d')}
            </div>
          )
        },
      },
    ],
    [checkboxes]
  )

  return (
    <Table
      className={styles.table}
      columns={columns}
      data={data}
      loading={loading}
      onClick={onRowClick}
    />
  )
}
export { ThreadsTable }
