import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  size,
  useClick,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import { LexicalEditor } from 'lexical'
import { useCallback, useState } from 'react'
import { ControlFontToolbar } from 'admin/components/ThreadForm/ControlFontToolbar'
import { Button } from 'components/Button'

interface Props {
  editor: LexicalEditor
}

const ControlFontButton = ({ editor }: Props) => {
  const [open, setOpen] = useState(false)

  const handleOpenChange = useCallback((nextOpen: boolean) => {
    setOpen(nextOpen)
  }, [])

  const { x, y, strategy, context, refs } = useFloating({
    open,
    onOpenChange: handleOpenChange,
    whileElementsMounted: autoUpdate,
    placement: 'top-start',
    middleware: [
      flip(),
      offset({ mainAxis: 16, crossAxis: -82 }),
      size({
        apply({ elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
          })
        },
      }),
    ],
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, { event: 'click' }),
    useRole(context, { role: 'tree' }),
  ])

  return (
    <>
      <Button
        variant="ghost"
        className="w-8 text-grey-600"
        active={open}
        {...getReferenceProps({
          ref: refs.setReference,
          onClick(e) {
            e.stopPropagation()
          },
        })}
      >
        Aa
      </Button>

      {open && (
        <FloatingPortal>
          <div
            {...getFloatingProps({
              ref: refs.setFloating,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                zIndex: 5,
              },
            })}
          >
            <ControlFontToolbar editor={editor} />
          </div>
        </FloatingPortal>
      )}
    </>
  )
}

export { ControlFontButton }
