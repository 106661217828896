import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { IMail } from 'types'
import { friendlyMime, friendlySize } from 'utils/file'
import { pluralize } from 'utils/pluralize'

interface Props {
  attachments: IMail['attachments']
}

const MailAttachments = ({ attachments }: Props) => {
  return (
    !!attachments?.length && (
      <Flex
        stack
        className="mt-5 pt-3 border-0 border-t border-solid border-grey-100"
      >
        <div className="text-lg text-grey-900 font-bold leading-5">
          {`${pluralize(attachments.length, 'attachment')}`}
        </div>
        <Flex flexWrap="wrap">
          {attachments.map((attachment) => (
            <Flex
              key={attachment.id}
              gap={10}
              alignItems="center"
              justifyContent="space-between"
              className="min-w-64 max-w-64 w-full p-1 pr-2.5 rounded border border-solid border-grey-100 hover:bg-grey-25"
            >
              <Flex gap={10} alignItems="center" className="truncate">
                {attachment?.previewUrl && (
                  <div
                    className="bg-center bg-no-repeat w-16 h-[46px] rounded-[5px] flex items-center justify-center bg-cover"
                    style={{
                      backgroundImage: `url(${attachment?.previewUrl})`,
                    }}
                  />
                )}
                <Flex stack gap={0} className="text-sm leading-[18px] truncate">
                  <Flex gap={4}>
                    <div className="text-grey-900 truncate">
                      {attachment.name}
                    </div>
                    <div className="text-grey-700">
                      {friendlyMime(attachment.type)}
                    </div>
                  </Flex>
                  <div className="text-grey-700">
                    {friendlySize(attachment.size)}
                  </div>
                </Flex>
              </Flex>
              <Flex gap={0}>
                <Button
                  variant="ghost"
                  onClick={(e) => {
                    e.stopPropagation()
                    console.log('Download')
                  }}
                  className="w-8"
                >
                  <Icon
                    name={IconName.download}
                    size="md"
                    className="text-grey-600"
                  />
                </Button>
                <Button
                  variant="ghost"
                  onClick={(e) => {
                    e.stopPropagation()
                    console.log('Save to')
                  }}
                  className="w-8"
                >
                  <Icon
                    name={IconName.moveFile}
                    size="md"
                    className="text-grey-600"
                  />
                </Button>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
    )
  )
}

export { MailAttachments }
