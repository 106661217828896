import {
  FloatingPortal,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react'
import clsx from 'clsx'
import { isNull, uniqBy, uniqueId, orderBy } from 'lodash'
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { INSERT_VARIABLE_COMMAND } from 'admin/components/InlineWysiwyg/plugins/VariablePlugin'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Input } from 'components/Input'
import { Option } from 'components/Select'
import { Text } from 'components/Text'
import type { LexicalEditor } from 'lexical'

interface Props {
  open: boolean
  onOpenChange: (open: boolean) => void
  variables: Option[]
  editor: LexicalEditor
}

function FormulaToolbarPlugin({
  open,
  onOpenChange: setOpen,
  variables,
  editor,
}: Props) {
  const searchRef = useRef<HTMLInputElement>(null)
  const listRef = useRef<(HTMLElement | null)[]>([])
  const [search, setSearch] = useState('')
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const visibleVariables = useMemo(
    () =>
      orderBy(
        uniqBy(
          variables.filter(({ label }) =>
            (label as string).toLowerCase().includes(search.toLowerCase())
          ),
          'value'
        ),
        'value'
      ),
    [variables, search]
  )

  const insertVariable = useCallback(
    (value: string) => {
      editor.dispatchCommand(INSERT_VARIABLE_COMMAND, {
        variable: value,
      })
    },
    [editor]
  )

  const { x, y, refs, context, strategy } = useFloating({
    open,
    onOpenChange: setOpen,
    // whileElementsMounted: autoUpdate,
    placement: 'bottom-end',
    middleware: [
      offset(4),
      size({
        apply({ availableHeight, elements }) {
          // Change styles, e.g.
          Object.assign(elements.floating.style, {
            minHeight: `${Math.max(150, availableHeight - 8)}px`,
            maxHeight: `${Math.min(300, availableHeight - 8)}px`,
          })
        },
      }),
    ],
  })
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useClick(context),
      useRole(context, { role: 'menu' }),
      useListNavigation(context, {
        listRef: listRef,
        activeIndex: activeIndex,
        onNavigate: setActiveIndex,
        virtual: true,
      }),
      useDismiss(context),
    ]
  )

  const handleSelect = useCallback(
    (variable: string) => {
      insertVariable(variable)
      setOpen(false)
      setSearch('')
    },
    [insertVariable, setOpen]
  )

  const width = (
    refs.reference.current as any
  )?.parentNode?.parentNode?.getBoundingClientRect().width as number

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        searchRef.current?.focus()
      }, 1)
    }
  }, [open])

  return (
    <div
      onKeyDown={(event) => {
        if (
          event.key === 'Enter' &&
          !isNull(activeIndex) &&
          visibleVariables[activeIndex]
        ) {
          event.preventDefault()
          handleSelect(visibleVariables[activeIndex].value as string)
          setActiveIndex(null)
          setOpen(false)
        }
      }}
    >
      <Flex
        gap={6}
        alignItems="center"
        justifyContent="center"
        className="cursor-pointer w-8 h-8 rounded-full text-lime-200 bg-grey-75 hover:bg-grey-100"
        {...getReferenceProps({
          ref: refs.setReference,
        })}
      >
        <Icon name={IconName.mergeField} size="md" />
      </Flex>

      {open && (
        <FloatingPortal>
          <div
            {...getFloatingProps({
              ref: refs.setFloating,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                width: width,
              },
            })}
            className="z-2 bg-white-100 rounded shadow-300 overflow-y-auto max-h-[300px]"
          >
            <Input
              ref={searchRef}
              placeholder="Search Fields"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              className="w-full !border-0 border-solid !border-b !border-grey-100 !rounded-none"
            />
            <div className="p-1">
              {!visibleVariables?.length ? (
                <Flex
                  stack
                  alignItems="center"
                  justifyContent="center"
                  className="pt-12 pb-16"
                >
                  <Icon
                    name={IconName.magnifyingGlass}
                    className="text-grey-500 w-7 h-7"
                  />
                  <Text variant="l">No search results</Text>
                </Flex>
              ) : (
                <div>
                  <div className="text-grey-700 mx-2 pt-3 pb-2 font-bold text-sm border-solid border-grey-200 border-0">
                    Select a Field
                  </div>
                  {visibleVariables.map((variableOption, index) => {
                    return (
                      <Fragment key={uniqueId(variableOption.value as string)}>
                        <Flex
                          justifyContent="left"
                          alignItems="center"
                          gap={8}
                          className={clsx(
                            'p-2 cursor-pointer rounded hover:bg-grey-75',
                            activeIndex === index && 'bg-grey-75'
                          )}
                          {...getItemProps({
                            ref(node) {
                              listRef.current[index] = node
                            },
                            onClick: () =>
                              handleSelect(variableOption.value as string),
                          })}
                        >
                          <Flex
                            stack
                            gap={4}
                            className="flex-grow overflow-hidden"
                          >
                            <div className="whitespace-nowrap text-grey-700 truncate">
                              {variableOption.value}
                            </div>
                          </Flex>
                        </Flex>
                      </Fragment>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </FloatingPortal>
      )}
    </div>
  )
}

export { FormulaToolbarPlugin }
