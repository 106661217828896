import clsx from 'clsx'
import { LexicalEditor } from 'lexical'
import { useState } from 'react'
import { useThreadContext } from 'admin/pages/Thread/ThreadContext'
import { Panel } from 'components/Panel'
import { IMail } from 'types'
import { Addressees } from './Addressees'
import { Body } from './Body'
import { Controls } from './Controls'
import { ModalAttachDocs } from './ModalAttachDocs/ModalAttachDocs'

interface Props {
  mail: IMail
}

const ThreadForm = ({ mail }: Props) => {
  const [isAttachDocs, setIsAttachDocs] = useState(false)
  const isResponse = !!mail.replyTo || !!mail.replyAllTo || !!mail.forwardTo
  const [editor, setEditor] = useState<LexicalEditor>()
  const { deleteMail, sendMail, updateMail } = useThreadContext()

  return (
    <Panel className={clsx('p-0', isResponse ? 'shadow-0' : 'shadow-300')}>
      <div className="py-3 border-0 border-b border-solid border-grey-200">
        <Addressees mail={mail} onChange={updateMail} />
        <Body mail={mail} onChange={updateMail} onInit={setEditor} />
      </div>
      {editor && (
        <Controls
          editor={editor}
          onSend={() => sendMail({ mailId: mail.id })}
          onAttachingDocs={() => setIsAttachDocs(true)}
          onDelete={() => deleteMail(mail.id)}
        />
      )}
      {isAttachDocs && (
        <ModalAttachDocs onClose={() => setIsAttachDocs(false)} />
      )}
    </Panel>
  )
}

export { ThreadForm }
