import { addBusinessDays, parseISO, format } from 'date-fns'
import { compact, sortBy } from 'lodash'
import { useState, useEffect } from 'react'
import { useAddLoanVendor, useLoanVendors } from 'admin/hooks/use-loan-vendors'
import { isLoanServicing } from 'admin/path-to'
import { Role, Vendor } from 'admin/services/api/vendors'
import { DatePicker } from 'components/DatePicker'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Select } from 'components/Select'
import { CreateOption } from 'components/Select/CreateOption'
import { Summary } from 'components/Summary'
import { Loan } from 'types'
import { ChooseVendor } from '../ChooseVendor'
import { PaymentInfoTypeHint } from './PaymentInfoTypeHint'
import { IPaymentInfo } from './types'

interface Props {
  paymentInfo: IPaymentInfo
  loan: Loan
  isScopeOfWork?: boolean
  isReleaseFunding?: boolean
  onChange: (paymentInfo: Partial<IPaymentInfo>) => void
}

const typeOptionsServicing = [
  { label: 'Regular Payment', value: 'Regular Payment' },
  { label: 'Payoff', value: 'Payoff' },
]

const typeOptionsBase = [
  { label: 'Other', value: 'Other' },
  { label: 'Special Payment', value: 'Special Payment' },
  { label: 'To Borrower', value: 'To Borrower' },
  { label: 'Funding', value: 'Funding' },
]

const paymentMethodOptions = [
  { label: 'Trust', value: 'Trust' },
  { label: 'ACH', value: 'ACH' },
  { label: 'Check', value: 'Check' },
  { label: 'Deposit', value: 'Deposit' },
  { label: 'Money Order', value: 'Money Order' },
  { label: 'Wire', value: 'Wire' },
  { label: 'Other', value: 'Other' },
]

const possibleVendors: Role[] = [
  'Closing Assistant',
  'Title Agent',
  'Escrow Office',
]

export const PaymentInfo = ({
  loan,
  isScopeOfWork,
  isReleaseFunding,
  paymentInfo,
  onChange,
}: Props) => {
  const [isFirstChangeOfDate, setIsFirstChangeOfDate] = useState(true)
  const [isAddSendToVisible, setIsAddSendToVisible] = useState(false)
  const { mutate: addVendor, isPending: addingVendor } = useAddLoanVendor()
  const { data: vendors } = useLoanVendors({
    loanId: loan.id,
    filter: { role: possibleVendors },
  })
  const sendToOptions = sortBy(
    [...loan.borrowers, ...loan.guarantors, ...(vendors?.vendors || [])].map(
      ({ id, companyName, name }) => ({
        label: compact([companyName, name]).join(' - '),
        value: id,
      })
    ),
    'label'
  )

  const isToBorrower = ['To Borrower', 'Funding'].includes(paymentInfo.type)

  useEffect(() => {
    if (
      sendToOptions?.length &&
      paymentInfo.type === 'Funding' &&
      !paymentInfo.sendTo
    ) {
      onChange({ sendTo: sendToOptions[0].value })
    }
  }, [sendToOptions, paymentInfo.type, paymentInfo.sendTo])

  useEffect(() => {
    if (
      isFirstChangeOfDate &&
      ['Regular Payment', 'Special Payment'].includes(paymentInfo.type) &&
      paymentInfo.method === 'ACH' &&
      paymentInfo.dateReceived === format(new Date(), 'yyyy-MM-dd')
    ) {
      onChange({
        dateRelease: format(addBusinessDays(new Date(), 3), 'yyyy-MM-dd'),
      })
      setIsFirstChangeOfDate(false)
    }
  }, [paymentInfo])

  const typeOptions = isLoanServicing(loan.status)
    ? [...typeOptionsServicing, ...typeOptionsBase]
    : typeOptionsBase

  return (
    <Flex stack gap={8}>
      <Header variant="h4">Payment Info</Header>
      <Grid gap={8}>
        <Grid.Item xs={3}>
          <Summary
            name={
              <Flex gap={4} alignItems="center">
                Type
                <PaymentInfoTypeHint
                  showFundingOnly={Boolean(isReleaseFunding)}
                />
              </Flex>
            }
          >
            <Select
              placeholder="Select..."
              options={
                isScopeOfWork
                  ? typeOptions.filter(({ value }) =>
                      ['Funding', 'To Borrower'].includes(value)
                    )
                  : typeOptions
              }
              isDisabled={isReleaseFunding}
              value={paymentInfo.type}
              portal
              onChange={(value) => onChange({ type: value.value as string })}
            />
          </Summary>
        </Grid.Item>
        <Grid.Item xs={3}>
          <Summary name="Method">
            <Select
              placeholder="Select..."
              options={paymentMethodOptions}
              value={paymentInfo.method}
              portal
              onChange={(value) => onChange({ method: value.value as string })}
            />
          </Summary>
        </Grid.Item>
        <Grid.Item xs={3}>
          <Summary name={isToBorrower ? 'Sent' : 'Received'}>
            <DatePicker
              value={paymentInfo.dateReceived}
              onChange={(value) =>
                onChange({ dateReceived: value, dateRelease: value })
              }
            />
          </Summary>
        </Grid.Item>
        <Grid.Item xs={3}>
          <Summary name="Release After">
            <DatePicker
              value={paymentInfo.dateRelease}
              minDate={
                paymentInfo.dateReceived
                  ? parseISO(paymentInfo.dateReceived)
                  : undefined
              }
              onChange={(value) => onChange({ dateRelease: value })}
            />
          </Summary>
        </Grid.Item>
        {paymentInfo.type === 'Special Payment' && (
          <Grid.Item xs={3}>
            <Summary name="Payment Through">
              <DatePicker
                value={paymentInfo.datePaidto}
                onChange={(value) => onChange({ datePaidto: value })}
              />
            </Summary>
          </Grid.Item>
        )}
        {paymentInfo.type === 'Funding' && (
          <Grid.Item xs={12}>
            <Summary name="Send To">
              <Select
                placeholder="Select..."
                options={sendToOptions}
                value={paymentInfo.sendTo}
                portal
                onChange={(value) =>
                  onChange({ sendTo: value.value as string })
                }
                createOption={
                  <CreateOption onClick={() => setIsAddSendToVisible(true)}>
                    <Flex
                      gap={4}
                      alignItems="center"
                      className="py-2 font-bold text-blue-200 hover:text-black-100"
                    >
                      <Icon name={IconName.plus} size="sm" />
                      Add a Closing Agent
                    </Flex>
                  </CreateOption>
                }
              />
            </Summary>
          </Grid.Item>
        )}
      </Grid>
      {isAddSendToVisible && (
        <ChooseVendor
          title="Add Closing Agent"
          createLinkText="Create a new Closing Agent"
          saving={addingVendor}
          onSave={(vendor) => {
            addVendor(
              { loanId: loan.id, vendor: vendor as Vendor },
              {
                onSuccess: (vendor) => {
                  onChange({ sendTo: vendor.id })
                  setIsAddSendToVisible(false)
                },
              }
            )
          }}
          onClose={() => {
            setIsAddSendToVisible(false)
          }}
          vendor={
            {
              role: possibleVendors as unknown,
            } as Vendor
          }
        />
      )}
    </Flex>
  )
}
