import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ThreadForm } from 'admin/components/ThreadForm'
import { MainContent } from 'admin/components/layout/MainContent'
import { useAddThread } from 'admin/hooks/use-threads'
import { ThreadProvider } from 'admin/pages/Thread/ThreadProvider'
import { pathTo } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { PageLoader } from 'components/LoaderOverlay'
import { IThread } from 'types'
import { Header } from './Header'

const ThreadNew = () => {
  const navigate = useNavigate()
  const { loanId } = useParams() as { loanId?: string }
  const { mutate: addThread } = useAddThread()
  const [thread, setThread] = useState<IThread>()

  const handleClose = useCallback(() => {
    if (loanId) {
      const isServicing = window.location.pathname.includes('/servicing')
      navigate(
        pathTo(isServicing ? 'servicingLoanTab' : 'loanTab', loanId, 'mail')
      )
    } else {
      navigate(pathTo('threads'))
    }
  }, [navigate, loanId])

  useEffect(() => {
    addThread({}, { onSuccess: (newThread) => setThread(newThread) })
  }, [addThread])

  return (
    <MainContent>
      {thread ? (
        <ThreadProvider thread={thread}>
          <Flex stack gap={32}>
            <Header onClose={handleClose} />
            <ThreadForm mail={thread.mails[0]} />
          </Flex>
        </ThreadProvider>
      ) : (
        <PageLoader />
      )}
    </MainContent>
  )
}

export { ThreadNew }
