import clsx from 'clsx'
import React, { useState, useEffect } from 'react'
import { Checkbox } from 'components/Checkbox'
import { Flex } from 'components/Flex'
import { LoanDocument } from 'types'
import { friendlyMime, friendlySize } from 'utils/file'
import { Section } from 'utils/loan-document-sections'
import { SelectedPerson, PersonWithDocuments } from './ModalAttachDocs'

interface Props {
  personWithDocuments: PersonWithDocuments[]
  setPersonWithDocuments: (val: PersonWithDocuments[]) => void
  selectedDocuments: string[]
  setSelectedDocuments: (val: string[]) => void
}

const SelectedDocuments = ({
  personWithDocuments,
  setPersonWithDocuments,
  selectedDocuments,
  setSelectedDocuments,
}: Props) => {
  const [copyOfPersonWithDocuments, setCopyOfPersonWithDocuments] = useState<
    PersonWithDocuments[]
  >([])

  useEffect(() => {
    setCopyOfPersonWithDocuments(personWithDocuments)
  }, [])

  const handleSelectDocument = (
    person: SelectedPerson,
    section: Section,
    document: LoanDocument
  ) => {
    if (selectedDocuments.includes(document.id)) {
      const updateDocumentsInSection = (
        sections: Section[],
        sectionName: string,
        documentId: string
      ) => {
        const updatedSections = sections.map((section) => {
          if (section.name !== sectionName) {
            return section
          }
          const updatedDocuments = section.documents.filter(
            (sectionDocument) => sectionDocument.id !== documentId
          )
          return { ...section, documents: updatedDocuments }
        })

        const filteredUpdatedSections = updatedSections.filter(
          (updatedSection) => !!updatedSection.documents.length
        )

        return filteredUpdatedSections
      }

      const updatedPersonWithDocuments = personWithDocuments.map(
        (personWithDocument) => {
          if (personWithDocument.person.id !== person.id) {
            return personWithDocument
          }
          const updatedSections = updateDocumentsInSection(
            personWithDocument.sections,
            section.name,
            document.id
          )
          return { ...personWithDocument, sections: updatedSections }
        }
      )

      const filteredUpdatedPersonWithDocuments =
        updatedPersonWithDocuments.filter(
          (updatedPersonWithDocument) =>
            !!updatedPersonWithDocument.sections.length
        )

      setPersonWithDocuments(filteredUpdatedPersonWithDocuments)
      setSelectedDocuments(
        selectedDocuments.filter((documentId) => documentId !== document.id)
      )
    } else {
      const updateDocumentsInSection = (
        sections: Section[],
        sectionName: string
      ) => {
        const hasSection = sections.some(
          (section) => section.name === sectionName
        )
        const updatedSections = hasSection
          ? sections.map((section) => {
              if (section.name !== sectionName) {
                return section
              }
              return { ...section, documents: [...section.documents, document] }
            })
          : [...sections, { ...section, documents: [document] }]

        return updatedSections
      }

      const hasPersonWithDocument = personWithDocuments.some(
        (personWithDocument) => personWithDocument.person.id === person.id
      )
      const updatedPersonWithDocuments = hasPersonWithDocument
        ? personWithDocuments.map((personWithDocument) => {
            if (personWithDocument.person.id !== person.id) {
              return personWithDocument
            }
            const updatedSections = updateDocumentsInSection(
              personWithDocument.sections,
              section.name
            )
            return { ...personWithDocument, sections: updatedSections }
          })
        : [
            ...personWithDocuments,
            { person, sections: [{ ...section, documents: [document] }] },
          ]

      setPersonWithDocuments(updatedPersonWithDocuments)
      setSelectedDocuments([...selectedDocuments, document.id])
    }
  }

  return (
    <div>
      {copyOfPersonWithDocuments.map((personWithDocument) => (
        <React.Fragment key={personWithDocument.person.id}>
          {personWithDocument.sections.map((section) => (
            <React.Fragment key={section.name}>
              <div className="py-2.5 pr-2 pl-3 text-sm text-grey-600 leading-5 border-0 border-b border-solid border-grey-100">
                {`${personWithDocument.person.name} / ${section.name}`}
              </div>
              {section.documents.map((document) => (
                <Flex
                  key={document.id}
                  gap={8}
                  alignItems="center"
                  onClick={() =>
                    handleSelectDocument(
                      personWithDocument.person,
                      section,
                      document
                    )
                  }
                  className={clsx(
                    'py-2.5 pr-2 pl-3 text-grey-700 leading-5',
                    document.size &&
                      !selectedDocuments.includes(document.id) &&
                      'cursor-pointer hover:bg-grey-50 hover:text-grey-800',
                    selectedDocuments.includes(document.id) &&
                      'cursor-pointer bg-purple-50 hover:text-grey-800',
                    !document.size && '!text-grey-500'
                  )}
                >
                  <Checkbox
                    disabled={!document.size}
                    checked={selectedDocuments.includes(document.id)}
                    onChange={() => {}}
                  />
                  <div>{document.name}</div>
                  {document.size && (
                    <div className="text-sm text-grey-700">{`${friendlyMime(document.type || '')} - ${friendlySize(document.size)}`}</div>
                  )}
                </Flex>
              ))}
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </div>
  )
}

export { SelectedDocuments }
