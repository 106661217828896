import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'

interface Props {
  onClose: () => void
}

const Header = ({ onClose }: Props) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      className="-mx-2 md:-mx-8 md:-mt-4 lg:-mx-14 md:border-0 md:border-b md:border-solid md:border-grey-100 md:h-12 pl-2"
    >
      <Flex gap={10} alignItems="center">
        <Button variant="ghost" onClick={onClose} className="w-8">
          <Icon name={IconName.close} size="md" className="text-grey-600" />
        </Button>
        <div className="text-2xl font-bold">New Message</div>
      </Flex>
      <div />
    </Flex>
  )
}

export { Header }
